import { certs } from "constants";
import { Loading } from "pages";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCertificateTypes } from "slices/certificateSlice";

function SelectCertificateComponent() {
  const { types } = useSelector((state) => state.certificate);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!types.length && !certs.length) dispatch(getCertificateTypes());
  }, [dispatch, types]);

  return (
    <>
      {certs.length ? (
        certs.map((type) => (
          <Link
            to={type.route}
            className="btn btn-lg btn-violet-outline fs-14 my-1 py-3 fw-5 col-12 col-sm-6 "
            key={type.orderBy}
          >
            {type.name}
          </Link>
        ))
      ) : (
        <Loading />
      )}
    </>
  );
}

export default SelectCertificateComponent;
