import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const NumberComponent = ({
  cns,
  options,
  setOptions,
  textLabel,
  textKey,
  textPlaceholder,
  nonMandatory,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">
        {textLabel ? textLabel : nonMandatory ? t("text") : t("textMandatory")}
      </div>
      <input
        type="number"
        className={`form-control fs-14 ${
          error?.key === textKey && "error-border"
        }`}
        name={textKey}
        placeholder={textPlaceholder}
        value={options[textKey]}
        onChange={(e) => handleChange(textKey, e.target.value)}
        required
      />
      {error?.key === textKey && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

NumberComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  textLabel: PropTypes.string,
  textKey: PropTypes.string,
  textPlaceholder: PropTypes.string,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default NumberComponent;
