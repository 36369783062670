import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import TextComponent from "./text.component";
import CountryComponent from "./country.component";

const WorkplaceComponent = ({ cns, options, setOptions }) => {
  const { t } = useTranslation();

  return (
    <div className={`row ${cns}`}>
      <TextComponent
        cns="col-sm-6"
        options={options}
        setOptions={setOptions}
        textLabel={t("workplaceNameMandatory")}
        textKey="orgName"
      />
      <CountryComponent
        cns="col-sm-6 mt-2 mt-sm-0"
        options={options}
        setOptions={setOptions}
        countryLabel={t("workplaceLocationMandatory")}
        countryKey="orgLocation"
      />
      <TextComponent
        cns="mt-2"
        options={options}
        setOptions={setOptions}
        textLabel={t("workplaceAddressMandatory")}
        textKey="orgAddress"
      />
    </div>
  );
};

WorkplaceComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
};

export default WorkplaceComponent;
