import {
  AddressComponent,
  AgeComponent,
  CareOfComponent,
  DateComponent,
  EmailComponent,
  FullNameComponent,
  GenderComponent,
  MobileComponent,
  TextAreaComponent,
  TextComponent,
  UploadFilesComponent,
  WorkplaceComponent,
} from "components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

function CaretakerComponent({ cns, options, setOptions, error }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${cns}`}>
        <h6>Your Details</h6>
        <hr />
        <>
          <div className="mt-2">
            <FullNameComponent
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="row mt-3">
            <DateComponent
              cns="col-sm-6"
              options={options}
              setOptions={setOptions}
              dateKey="dob"
              dateLabel={t("dateOfBirthMandatory")}
              error={error}
            />
            <AgeComponent
              cns="col-sm-6 mt-3 mt-sm-0"
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="mt-3">
            <EmailComponent
              cns=""
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="row mt-3">
            <GenderComponent
              cns="col-sm-6 mt-3 mt-sm-0"
              options={options}
              setOptions={setOptions}
              error={error}
            />
            <MobileComponent
              cns="col-sm-6 mt-3 mt-sm-0"
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="row mt-2">
            <DateComponent
              cns="col-sm-6"
              options={options}
              setOptions={setOptions}
              dateKey="leaveDateFrom"
              dateLabel={t("leaveFromMandatory")}
            />
            <DateComponent
              cns="col-sm-6"
              options={options}
              setOptions={setOptions}
              dateKey="leaveDateTo"
              dateLabel={t("leaveToMandatory")}
            />
          </div>
          <div className="row mt-2">
            <TextComponent
              options={options}
              setOptions={setOptions}
              textKey="leaveReason"
              textLabel={t("leaveReason")}
              error={error}
            />
          </div>
          <AddressComponent
            cns="mt-2"
            options={options}
            setOptions={setOptions}
          />
          <div className="mt-3">
            <WorkplaceComponent options={options} setOptions={setOptions} />
          </div>
        </>
      </div>
      <div className="mt-3">
        <h6 className="mb-0">Who&apos;re you taking care of?</h6>
        <hr />
        <CareOfComponent options={options} setOptions={setOptions} />
      </div>
      <div className="mt-3">
        <TextAreaComponent
          options={options}
          setOptions={setOptions}
          error={error}
          textareaLabel="Medical Condition Details *"
          textareaKey="reasonDescription"
        />
      </div>
      <div className="mt-3">
        <UploadFilesComponent options={options} setOptions={setOptions} />
      </div>
    </>
  );
}

CaretakerComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  error: PropTypes.object,
};

export default CaretakerComponent;
