import React from "react";

function LoginHeaderComponent() {
  return (
    <>
      <div className="pt-5 pt-sm-3 text-center">
        <img
          src="https://storage.googleapis.com/medoc-live.appspot.com/images/nmc_digital_india.png"
          className="img-nmc-logo img-responsive"
          alt="Medoc NMC digital india"
        />{" "}
      </div>
      <div className="fs-12 mt-2 fw-5 text-center">
        Every certificate adheres to guidelines outlined by{" "}
        <a
          href="https://www.nmc.org.in/nmc-act/"
          target="_blank"
          rel="nooopener noreferrer"
          className="text-violet"
        >
          <u>The National Medical Commission Act, 2019</u>
        </a>
      </div>
    </>
  );
}

export default LoginHeaderComponent;
