import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collections } from "constants";
import { signInWithEmailAndPassword } from "firebase/auth";
import { where } from "firebase/firestore";
import { auth, getId, getQuery } from "firebaseConfig";

// Async action to login the user
export const loginUser = createAsyncThunk(
  "user/login",
  async ({ email, password }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;
      const token = await user.getIdToken();
      sessionStorage.setItem("authToken", token);

      const users = await getQuery(collections.USERS, {
        whereQ: where("email", "==", user.email),
      });
      return users[0];
    } catch (error) {
      let message;
      if (error.message.includes("user-not-found")) {
        message = "Email not found";
      } else if (error.message.includes("wrong-password")) {
        message = "Wrong password";
      }
      return {
        status: 400,
        message,
      };
    }
  },
);

export const loadDoctorId =
  ({ userId }) =>
  async (dispatch) => {
    dispatch(userSlice.actions.loadUserStart());
    try {
      const result = await getId(collections.USERS, userId);
      if (!result.id) throw Error;
      dispatch(userSlice.actions.setDoctorDetails(result));
      dispatch(userSlice.actions.loadUserSuccess());
    } catch (error) {
      dispatch(userSlice.actions.loadUserFailure(error));
    }
  };

const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetails: null,
    dbUserDetails: null,
    doctorDetails: null,
    loading: null,
    error: null,
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.error = null;
    },
    setDbUserDetails: (state, action) => {
      state.dbUserDetails = action.payload;
      state.error = null;
    },
    setDoctorDetails: (state, action) => {
      state.doctorDetails = action.payload;
      state.error = null;
    },
    clearUserDetails: (state) => {
      state.userDetails = null;
      state.error = null;
    },
    clearDbUserDetails: (state) => {
      state.dbUserDetails = null;
      state.error = null;
    },
    clearDoctorDetails: (state) => {
      state.doctorDetails = null;
      state.error = null;
    },
    loadUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadUserSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    loadUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setUserDetails, setDbUserDetails, clearUserDetails } =
  userSlice.actions;
export default userSlice.reducer;
