import React from "react";
import PublicNavbar from "./publicNavbar";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <>
      <PublicNavbar />
      <div className="mt-5 pt-4 pt-sm-5 px-3 dashboard-content">
        <Outlet />
      </div>
      <div className="my-5 py-3"></div>
    </>
  );
};

export default PublicLayout;
