import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const DropdownComponent = ({
  cns,
  options,
  setOptions,
  textLabel,
  textKey,
  textPlaceholder,
  textValues,
  nonMandatory,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">
        {textLabel ? textLabel : nonMandatory ? t("text") : t("textMandatory")}
      </div>
      <select
        className="form-control fs-14"
        aria-label={textKey}
        value={options[textKey]}
        onChange={(e) => handleChange(textKey, e.target.value)}
      >
        <option>{textPlaceholder}</option>
        {textValues.map((tv) => (
          <option key={tv.key} value={tv.key}>
            {tv.value}
          </option>
        ))}
      </select>

      {error?.key === textKey && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

DropdownComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  textLabel: PropTypes.string,
  textKey: PropTypes.string,
  textPlaceholder: PropTypes.string,
  textValues: PropTypes.array,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default DropdownComponent;
