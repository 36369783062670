import { routes } from "constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const TermsComponent = ({ cns, options, setOptions }) => {
  const handleChange = (key, value) => {
    if (key === "step" && !options.confirmTnc) {
      return alert("Agree to all Terms and Conditions");
    }
    setOptions({
      ...options,
      confirmGuidelines: true,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="m-2 fs-12">
        <div className="mt-2">
          Our doctors are available from 7am to 9pm IST. I adhere to all{" "}
          <Link
            className="text-violet px-0 mx-0 fw-500 pointer"
            to={routes.GUIDELINES}
          >
            medoc guidelines.
          </Link>{" "}
          I hereby acknowledge that I will provide Medoc and their medical
          professionals with all the information they ask, whether it&apos;s
          before or after payment.
        </div>
        <div className="mt-2">
          I agree that the issuance of a medical certificate, prescription and
          its duration is based on the discretion of the consulting doctor and I
          fully comply with their accept or reject decision. If I require a sick
          leave certificate for more than 7 days, I understand that I must
          provide required medical reports.
        </div>
        <div className="mt-2">
          I confirm that I am not seriously unwell and I do NOT have any of the
          following symptoms: chest pain, shortness of breath, unable to swallow
          fluids or saliva, weakness or numbness down one side, and slurred
          speech. I confirm I do not think I need to see a General Practitioner.
          If symptoms are related to a COVID vaccine, I will consult my General
          Practitioner or the medical professional who provided the
          immunization. I confirm that I am not using the certificate for any
          medico-legal purposes. In case of non-issuance of the certificate due
          to valid reason, a service charge of up to 500 INR/- will be deducted.
          Kindly, read our{" "}
          <Link
            to={routes.REFUND_POLICY}
            target="_blank"
            className="text-violet fw-500"
          >
            Refund and cancellation policy{" "}
          </Link>
          for complete details. I have read, understood, and agree with the
          information in the terms of service and consent to the use of
          information in accordance with the privacy policy.
        </div>
        <div className="mt-2">
          If our medical team discovers that the information you provided is
          inaccurate or you requested a fake certificate, your details will be
          added to a blacklist, and <b>no refunds will be issued</b>.
        </div>
        {options.userId === "guest" && (
          <div className="mt-2">
            Guest user certificates or refunds may delay due to limited
            communications
          </div>
        )}
      </div>
      <div className="text-center">
        <div className="d-flex flex-row justify-content-center">
          <input
            className="form-check-input"
            type="checkbox"
            id="confirmTnc"
            onChange={() => handleChange("confirmTnc", true)}
            checked={options.confirmTnc}
          />
          <label className="fs-12 m-1" htmlFor="confirmTnc">
            {t("agreeToTerms")}
          </label>
        </div>
        <div
          className="btn btn-violet-rounded mt-2 px-4 py-1"
          onClick={() => handleChange("step", 2)}
        >
          {t("next")}
        </div>
      </div>
    </div>
  );
};

TermsComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
};

export default TermsComponent;
