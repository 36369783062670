import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const CountryComponent = ({
  cns,
  options,
  setOptions,
  countryLabel,
  countryKey,
  nonMandatory,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">
        {countryLabel
          ? countryLabel
          : nonMandatory
            ? t("country")
            : t("countryMandatory")}
      </div>
      <select
        className={`form-control fs-14 ${error?.key === countryKey && "error-border"}`}
        aria-label={countryKey}
        value={options[countryKey]}
        onChange={(e) => handleChange(countryKey, e.target.value)}
      >
        <option value="india">{t("india")}</option>
        <option value="abroad">{t("outsideIndia")}</option>
      </select>

      {error?.key === countryKey && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

CountryComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  countryLabel: PropTypes.string,
  countryKey: PropTypes.string,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default CountryComponent;
