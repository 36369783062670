import PropTypes from "prop-types";
import React from "react";

const DateComponent = ({
  cns,
  options,
  setOptions,
  dateLabel,
  dateKey,
  datePlaceholder,
  error,
}) => {
  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">{dateLabel}</div>
      <input
        type="date"
        className={`form-control fs-12 ${
          error?.key === dateKey && "error-border"
        }`}
        name="form-question"
        placeholder={datePlaceholder}
        value={options[dateKey]}
        onChange={(e) => handleChange(dateKey, e.target.value)}
      />
      {error?.key === dateKey && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

DateComponent.propTypes = {
  cns: PropTypes.string,
  dateLabel: PropTypes.string,
  dateKey: PropTypes.string,
  datePlaceholder: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  error: PropTypes.object,
};

export default DateComponent;
