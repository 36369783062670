import { concatMedicineDetails, handlePrescriptionErrors } from "helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextComponent from "./text.component";
import NumberComponent from "./number.component";
import DropdownComponent from "./dropdown.component";
import {
  prescriptionRoute,
  prescriptionFrequency,
  prescriptionDuration,
  prescriptionDose,
  prescriptionStrength,
  prescriptionPreparation,
  prescriptionDirection,
} from "constants";

const AddMedicineComponent = ({
  cns,
  options,
  setOptions,
  error,
  setError,
  setShowDrugCard,
}) => {
  const { t } = useTranslation();
  const drugObject = {
    name: "",
    strength: "",
    strengthUnit: "",
    preparation: "",
    route: "",
    dose: "",
    doseUnit: "",
    direction: "",
    frequency: "",
    duration: "",
    durationUnit: "",
    quantity: "",
    instructions: "",
  };
  const [drug, setDrug] = useState(drugObject);

  const saveDrug = async () => {
    const err = handlePrescriptionErrors(options, setError, drug);
    if (err) {
      return alert(err.message);
    }
    drug.details = concatMedicineDetails(drug);
    const prescription = {
      ...options,
      drugs: [...options.drugs, drug],
    };
    console.log("prescription", prescription);

    setOptions(() => ({
      ...options,
      drugs: [...options.drugs, drug],
    }));
    setDrug(drugObject);
    setShowDrugCard(false);
    alert("Drug is added. Don't forget to submit");
  };

  const clearDrug = () => {
    setDrug(drugObject);
    setShowDrugCard(false);
  };

  return (
    <div className={`col-12 card px-3 my-3 ${cns}`}>
      <div className="col-12 my-2 mt-3">
        <TextComponent
          options={drug}
          setOptions={setDrug}
          textKey="name"
          textPlaceholder={t("drugName")}
          textLabel={t("drugName")}
          error={error}
        />
      </div>
      <div className="row my-2">
        <NumberComponent
          cns="col-sm-6"
          options={drug}
          setOptions={setDrug}
          textKey="strength"
          textPlaceholder={t("strength")}
          textLabel={t("strength")}
          error={error}
        />
        <DropdownComponent
          cns="col-sm-6"
          options={drug}
          setOptions={setDrug}
          textKey="strengthUnit"
          textLabel={t("strengthUnit")}
          textValues={prescriptionStrength}
          error={error}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownComponent
          options={drug}
          setOptions={setDrug}
          textKey="preparation"
          textLabel={t("preparation")}
          textPlaceholder={t("selectPreparation")}
          textValues={prescriptionPreparation}
          error={error}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownComponent
          options={drug}
          setOptions={setDrug}
          textKey="route"
          textLabel={t("route")}
          textValues={prescriptionRoute}
          error={error}
        />
      </div>
      <div className="row my-2">
        <NumberComponent
          cns="col-sm-6"
          options={drug}
          setOptions={setDrug}
          textKey="dose"
          textPlaceholder={t("dose")}
          textLabel={t("dose")}
          error={error}
        />
        <DropdownComponent
          cns="col-sm-6"
          options={drug}
          setOptions={setDrug}
          textKey="doseUnit"
          textLabel={t("doseUnit")}
          textValues={prescriptionDose}
          error={error}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownComponent
          options={drug}
          setOptions={setDrug}
          textKey="direction"
          textLabel={t("direction")}
          textValues={prescriptionDirection}
          error={error}
        />
      </div>
      <div className="col-12 my-2">
        <DropdownComponent
          options={drug}
          setOptions={setDrug}
          textKey="frequency"
          textLabel={t("frequency")}
          textValues={prescriptionFrequency}
          error={error}
        />
      </div>
      <div className="row my-2">
        <NumberComponent
          cns="col-sm-6"
          options={drug}
          setOptions={setDrug}
          textKey="duration"
          textPlaceholder={t("duration")}
          textLabel={t("duration")}
          error={error}
        />
        <DropdownComponent
          cns="col-sm-6 mt-2 mt-sm-0"
          options={drug}
          setOptions={setDrug}
          textKey="durationUnit"
          textLabel={t("durationUnit")}
          textValues={prescriptionDuration}
          error={error}
        />
      </div>
      <div className="col-12 my-2">
        <NumberComponent
          options={drug}
          setOptions={setDrug}
          textKey="quantity"
          textPlaceholder={t("quantity")}
          textLabel={t("quantity")}
          error={error}
        />
      </div>
      <div className="col-12 my-2">
        <TextComponent
          options={drug}
          setOptions={setDrug}
          textKey="instructions"
          textPlaceholder={t("instructions")}
          textLabel={t("otherInstructions")}
          error={error}
        />
      </div>
      <div className="text-center my-3">
        <span
          className="btn btn-danger-outline btn-sm pointer mx-2"
          onClick={clearDrug}
        >
          {t("clear")}
        </span>
        <span className="btn btn-violet btn-sm pointer mr-3" onClick={saveDrug}>
          {t("saveDrug")}
        </span>
      </div>
    </div>
  );
};

AddMedicineComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  setShowDrugCard: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
};

export default AddMedicineComponent;
