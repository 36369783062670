import { PostAPI } from "api";
import { GoBackComponent, TextAreaComponent } from "components";
import { routes } from "constants";
import { collections } from "constants";
import { updateData } from "firebaseConfig";
import Loading from "pages/loading";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadRequestId } from "slices/getRequestSlice";

function FitnessGenerate() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { requestDetails: request } = useSelector((state) => state.request);
  const [options, setOptions] = useState(request || {});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadRequestId({ requestId: params?.id }));
    if (request && !options.id) {
      setOptions(request);
    }
  }, [dispatch, params, options, request]);

  const updateRequest = async () => {
    setLoading(true);
    if (window.confirm(t("confirmPaymentAlert"))) {
      const { status } = await updateData(
        collections.MEDICAL_REQUESTS,
        params.id,
        { ...options },
      );
      if (status !== 200) alert("Something went wrong. Try later!");
      else {
        const apiStatus = await PostAPI(routes.SEND_CERTIFICATE_API, {
          requestId: request.id,
        });
        if (apiStatus.success) {
          navigate(`${routes.REQUESTS}/${options.id}`);
        }
      }
    }
    setLoading(false);
  };

  return (
    options?.id && (
      <>
        <GoBackComponent route={`${routes.REQUESTS}/${params.id}`} />
        <div className="card card-2">
          <h5 className="mb-4 text-center">{t("fitnessCertificate")}</h5>
          <div className="mt-2 text-center">
            {t("requestNumber")} - {params.id}
          </div>
          <div className="row mt-3">
            <TextAreaComponent
              options={options}
              setOptions={setOptions}
              textareaKey="defaultMessage"
              textareaLabel={`${t("messageMandatory")} ${t("printsOnCertificate")}`}
            />
          </div>
          <div className="mt-3">
            <h6>I hereby confirm</h6>
            <div className="mt-2 fs-12">
              <i className="bi bi-check2-square me-2"></i>Name and adddress
              matched with the uploaded documents. <br />
              <i className="bi bi-check2-square me-2"></i>All details on the
              request are verified and accurate as per my knowledge. <br />
              <i className="bi bi-check2-square me-2"></i>I asked the patient to
              provide feedback <br />
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div
              className={`btn btn-violet-rounded fs-14 fw-5 mt-4`}
              onClick={updateRequest}
              disabled={loading}
            >
              Send Certificate
            </div>
          )}
        </div>
      </>
    )
  );
}

export default FitnessGenerate;
