import {
  AddressComponent,
  AgeComponent,
  DateComponent,
  EmailComponent,
  FullNameComponent,
  GenderComponent,
  MobileComponent,
  TextComponent,
  UploadFilesComponent,
  WorkplaceComponent,
} from "components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

function SickLeaveComponent({ cns, options, setOptions, error }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={`${cns}`}>
        <div className="mt-2">
          <FullNameComponent
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <div className="row mt-3">
          <DateComponent
            cns="col-sm-6"
            options={options}
            setOptions={setOptions}
            dateKey="dob"
            dateLabel={t("dateOfBirthMandatory")}
          />
          <AgeComponent
            cns="col-sm-6 mt-3 mt-sm-0"
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <div className="mt-3">
          <EmailComponent
            cns=""
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <div className="row mt-3">
          <GenderComponent
            cns="col-sm-6 mt-3 mt-sm-0"
            options={options}
            setOptions={setOptions}
            error={error}
          />
          <MobileComponent
            cns="col-sm-6 mt-3 mt-sm-0"
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <AddressComponent
          cns="mt-2"
          options={options}
          setOptions={setOptions}
        />
        <div className="row mt-2">
          <DateComponent
            cns="col-sm-6"
            options={options}
            setOptions={setOptions}
            dateKey="leaveDateFrom"
            dateLabel={t("leaveFromMandatory")}
          />
          <DateComponent
            cns="col-sm-6"
            options={options}
            setOptions={setOptions}
            dateKey="leaveDateTo"
            dateLabel={t("leaveToMandatory")}
          />
        </div>
        <div className="row mt-2">
          <TextComponent
            options={options}
            setOptions={setOptions}
            textKey="leaveReason"
            textLabel={t("leaveReason")}
            error={error}
          />
        </div>
        <div className="row mt-2">
          <TextComponent
            options={options}
            setOptions={setOptions}
            textKey="reasonDescription"
            textLabel={t("reasonDescription")}
            error={error}
          />
        </div>
        <UploadFilesComponent
          cns="mt-3"
          uploadFilesLabel="Address Proof & Medical reports *"
          uploadFilesCaption="Aadhar/Passport - Prescription, test resports, etc."
        />
        <div className="mt-3">
          <h6 className="fs-14 mt-4 text-center">
            To Whomsoever It May Concern
          </h6>
          <hr />
          <WorkplaceComponent options={options} setOptions={setOptions} />
        </div>
      </div>
    </>
  );
}

SickLeaveComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  error: PropTypes.object,
};

export default SickLeaveComponent;
