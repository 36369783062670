const productsCost = (options) => {
  let p;
  if (options?.orgLocation === "abroad") {
    p = options.product.abroadPrice;
    if (options.fasterPrice) {
      p += options.product.fasterPrice;
    }
  } else {
    p = options.product.price;
    if (options.fasterPrice) {
      p += options.product.fasterPrice;
    }
  }
  return p.toFixed(2);
};

export default productsCost;
