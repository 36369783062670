import {
  FitnessComponent,
  GoBackComponent,
  PaymentComponent,
  ProductsComponent,
} from "components";
import TermsComponent from "components/terms.component";
import { doctors } from "constants";
import { products as Products } from "constants";
import { collections } from "constants";
import { addData } from "firebaseConfig";
import { handleFitnessErrors, productsCost } from "helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Fitness() {
  const products = Products.filter((p) => p.category === "fitness").sort(
    (a, b) => a.orderBy - b.orderBy,
  );
  const { dbUserDetails } = useSelector((state) => state.user);
  const [options, setOptions] = useState({
    status: "pending",
    confirmGuidelines: false,
    confirmTnc: false,
    category: "Fitness",
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    address: {
      country: "India",
    },
    mobile: "",
    email: "",
    reason: "",
    otherReason: "",
    reasonDescription: "",
    leaveDateFrom: "",
    leaveDateTo: "",
    orgAddress: "",
    orgName: "",
    gender: "male",
    userId: dbUserDetails?.id || "guest",
    doctorId: doctors[0].id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorRemarks: [],
    additionalInfoRequired: false,
    height: "",
    heightMeasurement: "cm",
    weight: "",
    weightMeasurement: "kg",
    type: "fitness_certificate",
    subType: "",
    promocode: "",
    isPrescriptionRequired: false,
    files: [],
    orgLocation: "india",
    defaultMessage:
      "I also certify that I have examined the required medical history and have taken them into consideration in arriving at my decision that the patient is currently fit for all types of regular activities.",
    step: 1,
    fasterPrice: true,
  });
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const { t } = useTranslation();
  const { files } = useSelector((state) => state.document);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveRequest = async () => {
    const err = await handleFitnessErrors(options, setError, files);
    if (err && err.message) {
      return alert(err.message);
    }

    if (window.confirm(t("confirmPaymentAlert"))) {
      options.priority = options.product.priority;
      options.isPrescriptionRequired = options.product.prescription;
      options.files = files;
      options.product = {
        ...options.product,
        price: +productsCost(options),
      };
      if (dbUserDetails?.id) options.userId = dbUserDetails.id;

      const { status, data } = await addData(
        collections.MEDICAL_REQUESTS,
        options,
      );
      if (status !== 200) alert("Something went wrong. Try later!");
      return {
        collectionId: data.id,
        collectionName: collections.MEDICAL_REQUESTS,
      };
    }
  };

  return (
    <>
      <GoBackComponent showAlert="You lose all progress" />
      <div className="card card-2">
        <h5 className="mb-4 text-center">{t("fitnessCertificate")}</h5>
        {options.step === 1 && (
          <TermsComponent options={options} setOptions={setOptions} />
        )}
        {options.step === 2 && (
          <>
            <FitnessComponent
              options={options}
              setOptions={setOptions}
              error={error}
            />
            <div className="mt-3">
              <ProductsComponent
                products={products}
                options={options}
                setOptions={setOptions}
              />
            </div>
            <div className="mt-3 text-center">
              <PaymentComponent
                cns="payment-float-btn"
                data={options}
                customAction={saveRequest}
                collectionName={collections.MEDICAL_REQUESTS}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Fitness;
