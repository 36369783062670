import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDoctorId } from "slices/userSlice";

const DoctorIdComponent = ({ userId }) => {
  const dispatch = useDispatch();
  const { doctorDetails: doctor } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadDoctorId({ userId }));
  }, [dispatch, userId]);

  return (
    doctor?.id && (
      <div>
        <div className="fs-12 fw-5 text-secondary">
          Assigned to (doctor details)
        </div>
        <div className="d-flex flex-row align-items-center mt-2">
          <img
            src={doctor.photoURL || doctor.favicon}
            className="user-image"
            alt="Doctor Face"
          />
          <div>
            <div className="fw-5">Dr. {doctor.displayName}</div>
            <div className="fs-12">
              <i className="fa fa-envelope-o pr-1" /> {doctor.email}
            </div>
            <a
              href={`https://medoc.life/dr/${doctor.username}`}
              target="_blank"
              rel="noreferrer noopener"
              className="fs-12 text-violet"
            >
              <i className="fa fa-hospital-o pr-1 fs-16" /> Contact me at my
              Virtual Clinic for a range of services.
            </a>
          </div>
        </div>
      </div>
    )
  );
};

DoctorIdComponent.propTypes = {
  userId: PropTypes.string,
};

export default DoctorIdComponent;
