import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeDocument, uploadDocument } from "slices/documentSlice";

const UploadFilesComponent = ({
  cns,
  uploadFilesLabel,
  uploadFilesCaption,
  errorMessage,
  nonMandatory,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { files, loading, deleting, error } = useSelector(
    (state) => state.document,
  );

  const handleUpload = async (files) => {
    if (files.length) {
      Array.from(files).forEach((file) => dispatch(uploadDocument({ file })));
    } else {
      alert("Please select a new file!");
    }
  };

  const handleRemove = async (filePath) => {
    dispatch(removeDocument({ filePath }));
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fw-5 fs-14">
        {uploadFilesLabel
          ? uploadFilesLabel
          : nonMandatory
            ? t("documents")
            : t("documentsMandatory")}
      </div>
      <div className="fs-10 pb-2 fw-4">
        {uploadFilesCaption ? uploadFilesCaption : t("addDocumentsCaption")}
      </div>
      {files?.map((file) => (
        <div className="alert alert-violet px-2 py-2" key={file.fileName}>
          <span className="fs-12">{file.fileName}</span>
          <i
            className="bi bi-trash float-end text-light"
            onClick={() => handleRemove(file.url)}
          />
        </div>
      ))}
      {loading || deleting ? (
        <i className="spinner-border m-3 text-dark" />
      ) : (
        <div>
          <label htmlFor="uploadDoc">
            <i className="bi bi-plus-square text-violet pointer fs-24" />
          </label>
          <input
            type="file"
            className="d-none"
            multiple
            id="uploadDoc"
            onChange={(e) => handleUpload(e.target.files)}
            accept="application/*,image/*,video/*"
          />
        </div>
      )}
      {error && <p className="text-danger">{error || errorMessage}</p>}
    </div>
  );
};

UploadFilesComponent.propTypes = {
  cns: PropTypes.string,
  uploadFilesCaption: PropTypes.string,
  uploadFilesLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default UploadFilesComponent;
