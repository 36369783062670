import { createSlice } from "@reduxjs/toolkit";
import { collections } from "constants";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { firestore, getId, updateData } from "firebaseConfig";

export const loadRequests =
  ({ userId }) =>
  async (dispatch) => {
    dispatch(requestSlice.actions.loadRequestStart());

    try {
      const results = await getDocs(
        query(
          collection(firestore, collections.MEDICAL_REQUESTS),
          where("userId", "==", userId),
          orderBy("updatedAt", "desc"),
        ),
      );
      const resultsArray = [];
      results.forEach((doc) => {
        resultsArray.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch(requestSlice.actions.setAllRequests(resultsArray));
      dispatch(requestSlice.actions.loadRequestSuccess());
    } catch (error) {
      dispatch(requestSlice.actions.loadRequestFailure(error));
    }
  };

export const loadDoctorRequests =
  ({ doctorId, status }) =>
  async (dispatch) => {
    dispatch(requestSlice.actions.loadRequestStart());
    try {
      const results = await getDocs(
        query(
          collection(firestore, collections.MEDICAL_REQUESTS),
          where("doctorId", "==", doctorId),
          where("paymentStatus", "==", "completed"),
          where("status", "==", status),
          orderBy("updatedAt", "desc"),
          limit(100),
        ),
      );
      const resultsArray = [];
      results.forEach((doc) => {
        resultsArray.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch(requestSlice.actions.setAllRequests(resultsArray));
      dispatch(requestSlice.actions.loadRequestSuccess());
    } catch (error) {
      dispatch(requestSlice.actions.loadRequestFailure(error));
    }
  };

export const loadRequestId =
  ({ requestId }) =>
  async (dispatch) => {
    dispatch(requestSlice.actions.loadRequestStart());
    try {
      const result = await getId(collections.MEDICAL_REQUESTS, requestId);
      if (!result.id) throw Error;
      dispatch(requestSlice.actions.setRequestDetails(result));
      dispatch(requestSlice.actions.loadRequestSuccess());
    } catch (error) {
      dispatch(requestSlice.actions.loadRequestFailure(error));
    }
  };

export const assignDoctorId =
  ({ requestId, userId }) =>
  async (dispatch) => {
    dispatch(requestSlice.actions.loadRequestStart());
    try {
      const result = await updateData(collections.MEDICAL_REQUESTS, requestId, {
        doctorId: userId,
      });
      if (!result.id) throw Error;
      dispatch(requestSlice.actions.setRequestDetails(result));
      dispatch(requestSlice.actions.loadRequestSuccess());
    } catch (error) {
      dispatch(requestSlice.actions.loadRequestFailure(error));
    }
  };

const requestSlice = createSlice({
  name: "request",
  initialState: {
    requests: null,
    requestDetails: null,
    loading: null,
    error: null,
  },
  reducers: {
    setAllRequests: (state, action) => {
      state.requests = action.payload;
      state.error = null;
    },
    clearAllRequests: (state) => {
      state.requests = null;
      state.error = null;
    },
    setRequestDetails: (state, action) => {
      state.requestDetails = action.payload;
      state.error = null;
    },
    clearRequestDetails: (state) => {
      state.requestDetails = null;
      state.error = null;
    },
    loadRequestStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadRequestSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    loadRequestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loadRequestSuccess, loadRequestFailure } = requestSlice.actions;
export default requestSlice.reducer;
