import { PostAPI } from "api";
import { capitalizeFirstLetter } from "helpers";
import { Loading } from "pages";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import UploadFilesComponent from "./uploadFiles.component";
import { updateData } from "firebaseConfig";
import { collections, doctors, routes } from "constants";

const DoctorActionsComponent = ({ cns, request }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { dbUserDetails } = useSelector((state) => state.user);
  const { product } = request;
  const { files } = useSelector((state) => state.document);

  const getRoute = (request, action) => {
    const requestType =
      request.category ||
      (request.type
        ? capitalizeFirstLetter(request.type.replace(/_/g, " "))
        : "Sick Leave certificate");
    console.log("requestType", requestType);
    if (requestType?.includes("Caretaker")) {
      return action === "edit" ? routes.EDIT_CT : routes.GENERATE_CT;
    } else if (requestType?.includes("Sick Leave")) {
      return action === "edit" ? routes.EDIT_SL : routes.GENERATE_SL;
    } else if (requestType?.includes("Form 1A")) {
      return action === "edit" ? routes.EDIT_FA : routes.GENERATE_FA;
    } else if (requestType?.includes("Fitness")) {
      return action === "edit" ? routes.EDIT_FI : routes.GENERATE_FI;
    }
  };

  const savehandwrittenFiles = async () => {
    if (files.length < 1) {
      return alert("Please select files to proceed further");
    }
    setLoading(true);
    if (window.confirm(t("confirmPaymentAlert"))) {
      const response = await updateData(
        collections.MEDICAL_REQUESTS,
        request.id,
        {
          status: "accepted",
          certLink: files[0].url,
          prescriptionLink: files[1]?.url || "",
        },
      );
      if (response.status === 200) {
        await PostAPI(routes.SEND_EMAIL_API, {
          templateName: "approve_request.html",
          email: request.email,
          subject: `Your certificate is ready to download`,
          doctorName: "Medoc",
          requestId: request.id,
          displayName: request.firstName,
        });
        alert("Certificate was accepted successfully!");
        navigate(routes.REQUESTS);
      }
    }

    setLoading(false);
  };

  const reassignRequest = async () => {
    await updateData(collections.MEDICAL_REQUESTS, request.id, {
      status: "pending",
      doctorId: doctors[0].id,
    });
    alert("Request reassigned");
    window.location.reload();
  };

  const rejectRequest = async () => {
    if (window.confirm(t("confirmRejectRequest"))) {
      setLoading(true);
      await PostAPI("/request_reject", {
        requestId: request.id,
      });

      alert("Request is rejected");
      navigate(routes.REQUESTS);
    }
  };

  const acceptRequest = () => {
    if (request.isPrescriptionRequired && !request.prescriptionLink) {
      return alert(t("prescriptionRequired"));
    }

    navigate(`${getRoute(request, "generate")}/${request.id}`);
  };

  return (
    dbUserDetails?.isDoctor &&
    (request.status === "pending" ? (
      <div className={`col-12 ${cns}`}>
        <div className="d-flex flex-column flex-lg-row my-2">
          {getRoute(request, "edit") && (
            <Link
              to={`${getRoute(request, "edit")}/${request.id}`}
              className="btn btn-violet-outline-r btn-sm px-2 m-1"
            >
              <i className="bi bi-pencil me-1"></i>
              {t("editRequest")}
            </Link>
          )}
          <button
            className="btn btn-danger btn-sm px-2 m-1"
            onClick={rejectRequest}
          >
            {loading ? <Loading /> : t("reject")}
          </button>
        </div>
        <hr />
        {product.handwritten ? (
          <div>
            <UploadFilesComponent
              nonMandatory={true}
              uploadFilesLabel={`Upload Handwritten ${product.prescription ? "Prescription & Certificate" : "Certificate"}`}
              uploadFilesCaption="-"
            />
            <button
              className="btn btn-violet-rounded btn-sm px-2 m-1"
              onClick={savehandwrittenFiles}
            >
              {loading ? <Loading /> : t("accept")}
            </button>
          </div>
        ) : (
          <div className="d-flex flex-column flex-sm-row justify-content-center">
            {request.isPrescriptionRequired && (
              <Link
                to={`${routes.CREATE_PRESCRIPTION}/${request.id}`}
                className="btn btn-violet btn-sm px-2 m-1"
              >
                {t("createPrescription")}
              </Link>
            )}
            <button
              className="btn btn-violet btn-sm px-2 m-1"
              onClick={acceptRequest}
            >
              {loading ? <Loading /> : t("acceptRequest")}
            </button>
          </div>
        )}
      </div>
    ) : (
      <button
        className="btn btn-violet-outline-r btn-sm px-2 mt-4 col-2"
        onClick={reassignRequest}
      >
        {loading ? <Loading /> : t("reassign")}
      </button>
    ))
  );
};

DoctorActionsComponent.propTypes = {
  cns: PropTypes.string,
  request: PropTypes.object,
};

export default DoctorActionsComponent;
