import {
  AddressComponent,
  AgeComponent,
  DateComponent,
  EmailComponent,
  FullNameComponent,
  GenderComponent,
  MobileComponent,
  TextComponent,
  UploadFilesComponent,
} from "components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

function Form1aComponent({ cns, options, setOptions, error }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${cns}`}>
        <div className="mt-2">
          <FullNameComponent
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <div className="row mt-3">
          <DateComponent
            cns="col-sm-6"
            options={options}
            setOptions={setOptions}
            dateKey="dob"
            dateLabel={t("dateOfBirthMandatory")}
          />
          <AgeComponent
            cns="col-sm-6 mt-3 mt-sm-0"
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <div className="mt-3">
          <EmailComponent
            cns=""
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <div className="row mt-3">
          <GenderComponent
            cns="col-sm-6 mt-3 mt-sm-0"
            options={options}
            setOptions={setOptions}
            error={error}
          />
          <MobileComponent
            cns="col-sm-6 mt-3 mt-sm-0"
            options={options}
            setOptions={setOptions}
            error={error}
          />
        </div>
        <AddressComponent
          cns="mt-2"
          options={options}
          setOptions={setOptions}
        />
        <div className="row mt-2">
          <TextComponent
            cns="col-sm-6"
            options={options}
            setOptions={setOptions}
            textLabel={t("birthMarksMandatory")}
            textKey="birthMarks"
            textPlaceholder="2 birth marks required"
          />
          <TextComponent
            cns="col-sm-6"
            options={options}
            setOptions={setOptions}
            textLabel={t("fatherNameMandatory")}
            textKey="fatherName"
          />
        </div>
        <UploadFilesComponent
          cns="mt-3"
          uploadFilesLabel="Address Proof & Passport Size Photo *"
          uploadFilesCaption="Aadhar/Passport, Face picture & any other documents"
        />
      </div>
    </>
  );
}

Form1aComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  error: PropTypes.object,
};

export default Form1aComponent;
