import { signOut } from "firebase/auth";
import { auth } from "firebaseConfig";

const handleLogout = async () => {
  await signOut(auth);
  sessionStorage.clear();
  // navigate(routes.LOGIN);
  window.location.reload();
};

export default handleLogout;
