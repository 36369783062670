import { createSlice } from "@reduxjs/toolkit";
import { storage } from "firebaseConfig";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export const uploadDocument =
  ({ file, fileCategory }) =>
  async (dispatch) => {
    dispatch(uploadStart());
    try {
      fileCategory = fileCategory || "images"; // Could be avatars, audios, images
      const fileName = Date.now() + "_" + file.name?.toLowerCase();
      const storageRef = ref(storage, `app2/${fileCategory}/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          dispatch(uploadProgress({ fileName: file.name, progress }));
        },
        (error) => {
          dispatch(uploadFailure(error.message));
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          dispatch(uploadSuccess({ fileName: file.name, downloadURL }));
        },
      );
    } catch (error) {
      dispatch(uploadFailure(error.message));
    }
  };

export const removeDocument =
  ({ filePath }) =>
  async (dispatch) => {
    try {
      dispatch(removeStart());
      const storageRef = ref(storage, filePath);
      await deleteObject(storageRef);
      dispatch(removeSuccess(filePath));
    } catch (error) {
      dispatch(removeFailure(error.message));
    }
  };

const documentSlice = createSlice({
  name: "document",
  initialState: {
    files: [],
    loading: null,
    error: null,
    deleting: null,
  },
  reducers: {
    setFilesDetails: (state, action) => {
      state.files = action.payload;
      state.error = null;
    },
    clearFilesDetails: (state) => {
      state.files = [];
      state.error = null;
    },
    uploadStart(state) {
      state.loading = true;
      state.error = null;
    },
    uploadProgress: (state, action) => {
      const { fileName, progress } = action.payload;
      const file = state.files.find((file) => file.fileName === fileName);
      if (file) {
        file.progress = progress;
      }
    },
    uploadSuccess(state, action) {
      const { fileName, downloadURL } = action.payload;
      state.files.push({
        fileName,
        url: downloadURL,
        progress: 100,
      });
      state.loading = false;
    },
    uploadFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    removeStart: (state) => {
      state.deleting = true;
      state.error = null;
    },
    removeSuccess: (state, action) => {
      const filePath = action.payload;
      state.files = state.files.filter((file) => file.url !== filePath);
      state.deleting = false;
    },
    removeFailure: (state, action) => {
      state.deleting = false;
      state.error = action.payload;
    },
  },
});

export const {
  uploadStart,
  uploadProgress,
  uploadSuccess,
  uploadFailure,
  removeStart,
  removeSuccess,
  removeFailure,
  setFilesDetails,
  clearFilesDetails,
} = documentSlice.actions;
export default documentSlice.reducer;
