import { PostAPI } from "api";
import {
  MedicineComponent,
  GoBackComponent,
  TextAreaComponent,
} from "components";
import { routes } from "constants";
import { collections } from "constants";
import { updateData } from "firebaseConfig";
import { handlePrescriptionErrors } from "helpers";
import Loading from "pages/loading";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadRequestId } from "slices/getRequestSlice";

function Prescription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { requestDetails: request } = useSelector((state) => state.request);
  const prescriptionObject = {
    advice: "",
    diagnosis: "",
    complainsOf: "",
    drugs: [],
  };
  const [options, setOptions] = useState(prescriptionObject);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadRequestId({ requestId: params?.id }));
    if (request?.prescription && !options.diagnosis) {
      setOptions(request.prescription);
    }
  }, [dispatch, params, options, request]);

  const saveRequest = async () => {
    const err = await handlePrescriptionErrors(options, setError);
    if (err && err.message) {
      return alert(err.message);
    }

    setLoading(true);
    if (window.confirm(t("confirmPaymentAlert"))) {
      const { status } = await updateData(
        collections.MEDICAL_REQUESTS,
        params.id,
        { prescription: options },
      );
      if (status !== 200) alert("Something went wrong. Try later!");
      else {
        await PostAPI(routes.CREATE_PRESCRIPTION_API, {
          requestId: request?.id,
        });
        alert("Prescription is successfully created");
        navigate(`${routes.REQUESTS}/${params.id}`);
      }
    }
  };

  return (
    <>
      <GoBackComponent route={`${routes.REQUESTS}/${params.id}`} />
      <div className="card card-2">
        <h5 className="mb-4 text-center">{t("createPrescription")}</h5>
        <>
          <div className="mt-2">
            <TextAreaComponent
              options={options}
              setOptions={setOptions}
              textareaKey="diagnosis"
              textareaLabel={t("diagnosisMandatory")}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponent
              options={options}
              setOptions={setOptions}
              textareaKey="complainsOf"
              textareaLabel={t("complainsOf")}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponent
              options={options}
              setOptions={setOptions}
              textareaKey="advice"
              textareaLabel={t("advice")}
            />
          </div>
          <div className="mt-2">
            <MedicineComponent
              options={options}
              setOptions={setOptions}
              error={error}
              setError={setError}
            />
          </div>
          <hr />
          <div
            className={`btn btn-violet-outline-r fs-14 fw-5 mt-4`}
            onClick={saveRequest}
            disabled={loading}
          >
            {loading ? <Loading /> : t("submit")}
          </div>
        </>
      </div>
    </>
  );
}

export default Prescription;
