import {
  GoBackComponent,
  PaymentComponent,
  ProductsComponent,
} from "components";
import Form1aComponent from "components/certificates/form1a.component";
import TermsComponent from "components/terms.component";
import { products as Products } from "constants";
import { collections } from "constants";
import { addData } from "firebaseConfig";
import { handleForm1aErrors, productsCost } from "helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Form1a() {
  const products = Products.filter((p) => p.category === "form1a").sort(
    (a, b) => a.orderBy - b.orderBy,
  );
  const { dbUserDetails } = useSelector((state) => state.user);
  const [options, setOptions] = useState({
    status: "pending",
    confirmTnc: false,
    category: "Fitness: Form 1A",
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    mobile: "",
    email: "",
    address: {
      country: "India",
    },
    reasonDescription: "",
    birthMarks: "",
    fatherName: "",
    files: [],
    gender: "male",
    userId: dbUserDetails?.id || "guest",
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorId: "naga",
    doctorRemarks: [],
    isEditRequired: false,
    promocode: "",
    type: "form_1a",
    defaultMessage: "Form 1A",
    step: 1,
  });
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const { t } = useTranslation();
  const { files } = useSelector((state) => state.document);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveRequest = async () => {
    const err = await handleForm1aErrors(options, setError, files);
    if (err && err.message) {
      return alert(err.message);
    }

    if (window.confirm(t("confirmPaymentAlert"))) {
      options.priority = options.product.priority;
      options.files = files;
      options.product = {
        ...options.product,
        price: +productsCost(options),
      };
      if (dbUserDetails?.id) options.userId = dbUserDetails.id;

      const { status, data } = await addData(
        collections.MEDICAL_REQUESTS,
        options,
      );
      if (status !== 200) alert("Something went wrong. Try later!");

      return {
        collectionId: data.id,
        collectionName: collections.MEDICAL_REQUESTS,
      };
    }
  };

  return (
    <>
      <GoBackComponent showAlert="You lose all progress" />
      <div className="card card-2">
        <h5 className="mb-4 text-center">{t("form1aCertificate")}</h5>
        {options.step === 1 && (
          <TermsComponent options={options} setOptions={setOptions} />
        )}
        {options.step === 2 && (
          <>
            <Form1aComponent
              options={options}
              setOptions={setOptions}
              error={error}
            />
            <div className="mt-3">
              <ProductsComponent
                products={products}
                options={options}
                setOptions={setOptions}
                noAddons={true}
              />
            </div>
            <div className="mt-3 text-center">
              <PaymentComponent
                cns="payment-float-btn"
                data={options}
                customAction={saveRequest}
                collectionName={collections.MEDICAL_REQUESTS}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Form1a;
