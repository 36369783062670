const concatMedicineDetails = (drug) => {
  let dn = drug.name.toUpperCase();
  if (drug.strength) {
    dn = `${dn} ${drug.strength}`;
  }
  if (drug.strengthUnit) {
    dn = `${dn}${drug.strengthUnit}`;
  }
  if (drug.preparation) {
    dn = `${drug.preparation.toUpperCase()}. ${dn}`;
  }
  if (drug.route) {
    dn = `${dn} ${drug.route.toUpperCase()}`;
  }
  if (drug.dose) {
    dn = `${dn} ${drug.dose}`;
  }
  if (drug.doseUnit) {
    dn = `${dn} ${drug.doseUnit.replace(/_/g, " ")}`;
  }
  if (drug.direction) {
    dn = `${dn} ${drug.direction.toUpperCase().replace(/_/g, " ")}`;
  }
  if (drug.frequency) {
    dn = `${dn} ${drug.frequency.toUpperCase().replace(/_/g, " ")}`;
  }
  if (drug.duration) {
    dn = `${dn} ${drug.duration.toUpperCase()}`;
  }
  if (drug.durationUnit) {
    dn = `${dn} ${drug.durationUnit.toUpperCase()}`;
  }
  if (drug.quantity) {
    dn = `${dn} --- ${drug.quantity.toUpperCase()}`;
  }
  if (drug.instructions) {
    dn = `${dn} ${drug.instructions.toUpperCase()}`;
  }
  return dn;
};

export default concatMedicineDetails;
