import { PostAPI } from "api";
import { routes } from "constants";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "./loading";
import { useSelector } from "react-redux";

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { collectionId, order, response, userEmail } = location.state;
  const { dbUserDetails } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "development") {
      window.gtag("event", "conversion", {
        send_to: "AW-11027898121/TJgaCNW4tIgYEIm-wYop",
        value: order.amount / 100 < 700 ? order.amount / 100 : 510,
        currency: order.currency,
        transaction_id: response.razorpay_payment_id,
      });
    }
    window.scrollTo(0, 0);
  }, [order, response]);

  const completeTransaction = async () => {
    setLoading(true);
    await PostAPI(routes.RECHARGE_TRANSACTION, {
      amount: order.amount,
      paymentIntentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      currency: order.currency,
      requestId: collectionId,
      signature: response.razorpay_signature,
      userId: dbUserDetails?.id || "guest",
      userEmail: dbUserDetails?.email || userEmail,
    });
    navigate(`${routes.REQUESTS}/${collectionId}`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(collectionId);
    alert("Your request number is copied!");
  };

  return !collectionId || loading ? (
    <div>
      <Loading />
      <div className="text-center mt-5 pt-5">
        Request is sending to a doctor. Please donot refresh your screen...
      </div>
    </div>
  ) : (
    <div className="dashboard-content pt-5 mt-5 text-center">
      <h3>Your payment is successful.</h3>
      <h6 className="my-4">
        Request Number is <br /> <br />{" "}
        <span className="text-violet pointer" onClick={copyToClipboard}>
          {collectionId} <i className="bi bi-copy"></i>
        </span>{" "}
      </h6>
      <div
        className="btn btn-violet-rounded px-4 mt-1"
        onClick={completeTransaction}
      >
        Submit Request
      </div>
    </div>
  );
};

export default PaymentSuccess;
