import { routes } from "constants";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

const GoBackComponent = ({ route }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    return route ? navigate(route) : navigate(routes.DASHBOARD);
  };

  return (
    <div
      className="btn btn-violet-rounded px-3 btn-sm my-3"
      onClick={handleRedirect}
    >
      Go Back
    </div>
  );
};

GoBackComponent.propTypes = {
  route: PropTypes.string,
};

export default GoBackComponent;
