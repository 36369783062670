import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/** Declare custom exports here */
import { loginUser } from "slices/userSlice";
import withAuthRedirect from "routes/withAuthRedirect";
import { routes } from "constants";
import { LoginHeaderComponent } from "components";
import { handleLoginErrors } from "helpers";
import Loading from "./loading";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    const err = await handleLoginErrors({ email, password }, setError);
    if (err && err.message) {
      setError(err.message);
      return;
    }
    setLoading(true);
    dispatch(loginUser({ email, password }))
      .unwrap()
      .then((res) => {
        if (res.status === 400) {
          setError(res.message);
          setLoading(false);
          return;
        }
        // navigate(routes.DASHBOARD); // Redirect on successful login
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Login failed:", error);
      });
  };

  return (
    <>
      <LoginHeaderComponent />
      <div className="mt-5 card card-2">
        <div className="text-center">
          <Link
            to={routes.DASHBOARD}
            className="btn btn-violet mt-3 col-12 col-sm-4"
          >
            {" "}
            {t("continueAsGuest")}{" "}
          </Link>
          <div className="line-container">
            <div className="line"></div>
            <span className="line-text">OR</span>
            <div className="line"></div>
          </div>
        </div>
        <h3>{t("login")}</h3>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            className={`form-control fs-14 mt-2 py-2`}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="password"
            value={password}
            className={`form-control fs-14 mt-2 py-2`}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          {error && (
            <div className="text-danger fs-12 fw-5 mt-3">
              <i className="bi bi-exclamation-triangle-fill me-1"></i>
              {error}
            </div>
          )}
          {loading ? (
            <div className="mt-3">
              <Loading />
            </div>
          ) : (
            <button type="submit" className="btn btn-violet mt-3 col-sm-3">
              {t("login")}
            </button>
          )}
        </form>
        <Link to={routes.SIGNUP} className="text-violet mt-3">
          {" "}
          {t("redirectToSignUp")}{" "}
        </Link>
        <Link to={routes.FORGOT_PASSWORD} className="text-violet mt-1">
          {" "}
          {t("forgotPassword")}{" "}
        </Link>
      </div>
    </>
  );
}

export default withAuthRedirect(Login);
