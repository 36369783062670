const handlePrescriptionErrors = (options, setError, drug) => {
  let error;
  if (!options.diagnosis) {
    error = {
      message: "Diagnosis is required",
      key: "diagnosis",
    };
  }
  if (drug && !drug.name) {
    error = {
      message: "Drug name is required",
      key: "drugName",
    };
  } else if (drug && drug.strength && !drug.strengthUnit) {
    error = {
      message: "Strength Unit is required",
      key: "strengthUnit",
    };
  } else if (drug && drug.dose && !drug.doseUnit) {
    error = {
      message: "Dose Unit is required",
      key: "doseUnit",
    };
  } else if (drug && drug.duration && !drug.durationUnit) {
    error = {
      message: "Duration Unit is required",
      key: "durationUnit",
    };
  }
  setError(error);
  return error;
};

export default handlePrescriptionErrors;
