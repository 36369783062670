const handleFitnessErrors = (options, setError, files = []) => {
  let error;
  if (!options.firstName) {
    error = {
      message: "First name is required",
      key: "firstName",
    };
  } else if (!options.lastName) {
    error = {
      message: "Last name is required",
      key: "lastName",
    };
  } else if (!options.dob) {
    error = {
      message: "Date of birth is required",
      key: "dob",
    };
  } else if (!options.age) {
    error = {
      message: "Age is required",
      key: "age",
    };
  } else if (!options.email) {
    error = {
      message: "Email is required",
      key: "email",
    };
  } else if (!options.mobile) {
    error = {
      message: "Mobile number is required",
      key: "mobile",
    };
  } else if (
    options.mobile &&
    options.orgLocation === "india" &&
    options.mobile.length !== 12
  ) {
    error = {
      message: "Mobile number must contain 12-digits",
      key: "mobile",
    };
  } else if (!options.address.door) {
    error = {
      message: "Door no. is required",
      key: "address",
    };
  } else if (!options.address.streetName) {
    error = {
      message: "Street name is required",
      key: "address",
    };
  } else if (!options.address.city) {
    error = {
      message: "City name is required",
      key: "address",
    };
  } else if (!options.address.state) {
    error = {
      message: "State is required",
      key: "address",
    };
  } else if (!options.address.zipcode) {
    error = {
      message: "Zipcode is required",
      key: "address",
    };
  } else if (!options.height) {
    error = {
      message: "Height (in cm) is required",
      key: "height",
    };
  } else if (!options.weight) {
    error = {
      message: "Weight (in Kg) is required",
      key: "weight",
    };
  } else if (files?.length < 2) {
    error = {
      message:
        "Address proof & 15 seconds walking video are required (Your face must be visible)",
      key: "files",
    };
  } else if (!options.orgName) {
    error = {
      message: "Organisation name is required",
      key: "orgName",
    };
  } else if (!options.orgAddress) {
    error = {
      message: "Organisation address is required",
      key: "orgAddress",
    };
  } else if (!options.product) {
    error = {
      message: "Choose a product",
      key: "product",
    };
  }
  setError(error);
  return error;
};

export default handleFitnessErrors;
