import React from "react";
import { Navigate } from "react-router-dom";

const withAuthRedirect = (WrappedComponent) => {
  const HOC = (props) => {
    const token = sessionStorage.getItem("authToken");

    if (token) {
      return <Navigate to="/dashboard" replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

// Set display name manually for easier debugging (for higher order components)
withAuthRedirect.displayName = "withAuthRedirect";

export default withAuthRedirect;
