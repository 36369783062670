import PropTypes from "prop-types";
import React from "react";

const TextExtraKeyComponent = ({
  cns,
  options,
  setOptions,
  textKey,
  textPlaceholder,
  textExtraKey,
  error,
}) => {
  const handleChange = (key, value, extraField) => {
    setOptions({
      ...options,
      [key]: {
        ...options[key],
        [extraField]: value,
      },
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <input
        type="text"
        className={`form-control fs-14 ${
          error?.key === textExtraKey && "error-border"
        }`}
        name={textExtraKey}
        placeholder={textPlaceholder}
        value={options[textKey][textExtraKey]}
        onChange={(e) => handleChange(textKey, e.target.value, textExtraKey)}
        required
      />
      {error?.key === textExtraKey && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

TextExtraKeyComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  textKey: PropTypes.string,
  textPlaceholder: PropTypes.string,
  textExtraKey: PropTypes.string,
  error: PropTypes.object,
};

export default TextExtraKeyComponent;
