import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadFilesComponent from "./uploadFiles.component";
import TextAreaComponent from "./textarea.component";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "firebaseConfig";
import { collections } from "constants";
import moment from "moment";
import { clearFilesDetails } from "slices/documentSlice";
import { PostAPI } from "api";
import { routes } from "constants";
import { Loading } from "pages";

const CommentsComponent = ({ cns, request }) => {
  const { dbUserDetails } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [comment, setComment] = useState({
    type: dbUserDetails?.isDoctor ? "d2p" : "p2d",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [doctorRemarks, setDoctorRemarks] = useState(
    request?.doctorRemarks || [],
  );
  const { files } = useSelector((state) => state.document);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFilesDetails());
  }, [dispatch]);

  const saveComment = async () => {
    if (!comment.message) {
      return alert("Message is mandatory");
    }
    setLoading(true);
    let comments = [
      ...doctorRemarks,
      {
        ...comment,
        files,
        createdAt: new Date().getTime(),
      },
    ].sort((a, b) => b.createdAt - a.createdAt);
    setDoctorRemarks(comments);

    const res = await updateData(collections.MEDICAL_REQUESTS, request?.id, {
      doctorRemarks: comments,
      additionalInfoRequired: dbUserDetails?.isDoctor || false,
    });
    if (res.status === 200) {
      dispatch(clearFilesDetails());
      if (dbUserDetails?.isDoctor) {
        // Send email only if comment has gap 5 mins
        await PostAPI(routes.SEND_EMAIL_API, {
          templateName: "additional_info_request.html",
          email: dbUserDetails?.isDoctor ? request.email : dbUserDetails?.email,
          subject: `You've a message from our doctor`,
          doctorName: "Medoc",
          doctorRemarks: comment.message,
          requestId: request.id,
          displayName: request.firstName,
        });
      }
      setComment({
        type: dbUserDetails?.isDoctor ? "d2p" : "p2d",
        message: "",
        files: [],
      });
      setLoading(false);
      alert("Your comment is submitted!");
    }
  };

  return (
    <div className={`col-12 card card-3 mt-4 ${cns}`}>
      <h5 className="fw-5 mb-1 text-center">Comments</h5>
      {request.paymentStatus === "completed" &&
        request.status === "pending" && (
          <>
            <div className="row">
              <TextAreaComponent
                cns="my-2"
                options={comment}
                setOptions={setComment}
                textareaKey={"message"}
                textareaLabel={t("messageMandatory")}
              />
              <UploadFilesComponent
                nonMandatory={true}
                uploadFilesLabel="Documents (if any)"
              />
              <div className="mt-2">
                {loading ? (
                  <Loading />
                ) : (
                  <button
                    className="btn btn-violet-rounded btn-sm col-2"
                    onClick={saveComment}
                  >
                    Send
                  </button>
                )}
              </div>
            </div>
            <hr />
          </>
        )}
      <div>
        {doctorRemarks?.length > 0 ? (
          doctorRemarks.map((remark, ridx) => (
            <div className="py-1" key={ridx}>
              <span className="me-1">
                <b>
                  {remark.type === "d2p" || remark["d2p"]
                    ? "Doctor"
                    : "Patient"}
                </b>
                :{" "}
                {remark?.message ||
                  (remark.type === "d2p" || remark["d2p"]
                    ? remark["d2p"]
                    : remark["p2d"])}
              </span>
              {remark?.files &&
                remark.files.map((file, idx) => (
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet col-12"
                    key={idx}
                  >
                    Doc {idx + 1} &nbsp;
                  </a>
                ))}
              <span className="fs-10">
                {moment(remark.createdAt).format("DD/MM/YYYY HH:MM")}
              </span>
            </div>
          ))
        ) : (
          <div className="text-center">No comments yet</div>
        )}
      </div>
    </div>
  );
};

CommentsComponent.propTypes = {
  cns: PropTypes.string,
  request: PropTypes.object,
};

export default CommentsComponent;
