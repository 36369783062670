import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddMedicineComponent from "./addMedicine.component";

const MedicineComponent = ({ cns, options, setOptions, error, setError }) => {
  const { t } = useTranslation();
  const [showDrugCard, setShowDrugCard] = useState(false);

  const removeDrug = (idx) => {
    const drugs = options.drugs.filter((d, index) => index !== idx);
    setOptions({
      ...options,
      drugs,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">{t("addMedicines")}</div>
      <div>
        {options.drugs.length > 0 &&
          options.drugs.map((drug, drugIdx) => (
            <div key={drugIdx}>
              {drug.details}
              <i
                className="bi bi-trash text-danger"
                onClick={() => removeDrug(drugIdx)}
              />
            </div>
          ))}
      </div>
      {showDrugCard ? (
        <AddMedicineComponent
          options={options}
          setOptions={setOptions}
          error={error}
          setError={setError}
          setShowDrugCard={setShowDrugCard}
        />
      ) : (
        <div
          className="btn btn-violet btn-sm my-2"
          onClick={() => setShowDrugCard(!showDrugCard)}
        >
          {t("addDrug")}
        </div>
      )}
    </div>
  );
};

MedicineComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
};

export default MedicineComponent;
