import { RequestStatusComponent } from "components";
import { routes } from "constants";
import { capitalizeFirstLetter } from "helpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loadDoctorRequests, loadRequests } from "slices/getRequestSlice";

const Requests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dbUserDetails } = useSelector((state) => state.user);
  const { requests } = useSelector((state) => state.request);
  const { t } = useTranslation();
  const [showAllStatus, setShowAllStatus] = useState("pending");

  useEffect(() => {
    if (dbUserDetails?.isDoctor && !requests) {
      dispatch(
        loadDoctorRequests({
          doctorId: dbUserDetails?.id,
          status: showAllStatus,
        }),
      );
    }
    if (!dbUserDetails?.isDoctor && !requests) {
      dispatch(loadRequests({ userId: dbUserDetails?.id }));
    }
  }, [dispatch, dbUserDetails, requests, showAllStatus]);

  const redirectToRequestId = (requestId) => {
    navigate(`${routes.REQUESTS}/${requestId}`);
  };

  const showRequests = (status) => {
    dispatch(loadDoctorRequests({ doctorId: dbUserDetails?.id, status }));
    setShowAllStatus(status);
  };

  return (
    <div>
      <div className="text-center">
        <Link to={routes.DASHBOARD} className="btn btn-violet-rounded px-4">
          <i className="bi bi-telephone-inbound me-2"></i> {t("askDoctor")}
        </Link>
      </div>
      {dbUserDetails?.isDoctor && (
        <select
          onChange={(e) => showRequests(e.target.value)}
          value={showAllStatus}
        >
          <option value="pending">Pending</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
      )}
      {requests?.length > 0 ? (
        <>
          <div className="requests-wrapper">
            {requests.map((request, idx) => (
              <div key={idx} className="card card-2 my-3">
                <RequestStatusComponent request={request} />
                <hr />
                <div
                  className="row pointer"
                  onClick={() => redirectToRequestId(request.id)}
                >
                  <div className="col-12 col-sm-6">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("requestType")}
                    </div>
                    <div>
                      {request.category ||
                        (request.type
                          ? capitalizeFirstLetter(
                              request.type.replace(/_/g, " "),
                            )
                          : "Sick leave certificate")}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("requestNumber")}
                    </div>
                    <div>{request.id}</div>
                  </div>
                  <div className="mt-3 col-12 col-sm-6">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("fullName")}
                    </div>
                    <div>
                      {request.firstName} {request.lastName}
                    </div>
                  </div>
                  <div className="mt-3 col-12 col-sm-6">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("lastUpdated")}
                    </div>
                    <div>
                      {moment(request.updatedAt).format("DD-MM-YYYY, HH:mm")}
                    </div>
                  </div>
                  {request.certLink && (
                    <div className="mt-3 col-12 col-sm-6 pl-0">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("certificateLink")}
                      </div>
                      <a
                        href={request.certLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-violet fw-5"
                      >
                        {t("download")}
                        <i className="fa fa-arrow-circle-o-down" />
                      </a>
                    </div>
                  )}
                  {request.prescriptionLink && (
                    <div className="mt-3 col-12 col-sm-6 pl-0">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("prescriptionLink")}
                      </div>
                      <a
                        href={request.prescriptionLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-violet fw-5"
                      >
                        {t("download")}
                        <i className="fa fa-arrow-circle-o-down" />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-center pt-5">No requests made yet.</div>
        </>
      )}
    </div>
  );
};

export default Requests;
