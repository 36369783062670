import { isSmallDevice, productsCost } from "helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ProductsComponent = ({ products, options, setOptions, noAddons }) => {
  const { t } = useTranslation();
  const [hidePrescriptionAlert, setHidePrescriptionAlert] = useState(true);

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div>
      <div className="fs-14 fw-5 mb-1">{t("availableProducts")}</div>
      {products.map(
        (product, idx) =>
          product.display && (
            <div key={idx} className="my-3">
              <input
                className="form-check-input col-2 mt-1"
                type="radio"
                name="product"
                id={`product-${idx}`}
                onChange={() => handleChange("product", product)}
                checked={options.product.name === product.name}
              />
              <label
                className="form-check-label col-10 col-sm-11 ps-3"
                htmlFor={`product-${idx}`}
              >
                <div
                  className={`d-flex ${isSmallDevice() ? "flex-column" : "flex-row justify-content-between"}`}
                >
                  <span className="fw-5">{product.name}</span>{" "}
                  {isSmallDevice() && <div></div>}
                  <span>
                    {t("indianCurrency")}
                    <span>
                      {options.orgLocation === "abroad"
                        ? product.abroadPrice.toFixed(2)
                        : product.price.toFixed(2)}
                    </span>{" "}
                    &nbsp;
                    <strike className="text-orange-600 fw-5 mt-2 fs-12 d-none">
                      {t("indianCurrency")} {product.totalPrice.toFixed(2)}
                    </strike>
                  </span>
                </div>
                <div className="fs-12">{product.description}</div>
              </label>
            </div>
          ),
      )}
      {/* Add-ons */}
      <div>
        {!noAddons && (
          <div className="pt-4 text-center">
            <input
              className="form-check-input ml-1 mt-2"
              type="checkbox"
              name="fasterPrice"
              id="fasterPrice"
              onChange={() => handleChange("fasterPrice", !options.fasterPrice)}
              checked={options.fasterPrice}
            />
            <label
              className={`form-check-label ml-4 mt-1 text-justify`}
              htmlFor="fasterPrice"
            >
              <small className="fw-5 ms-2">
                Get priority service for {t("indianCurrency")}
                {options.product.fasterPrice}
                .00/-
              </small>
            </label>{" "}
            <i
              className="bi bi-info-circle pointer text-violet d-none"
              onClick={() => setHidePrescriptionAlert(!hidePrescriptionAlert)}
            />
          </div>
        )}
      </div>
      {/* Total Cost */}
      <div className="mt-4 text-center fs-16 fw-5">
        Total cost:{" "}
        <span className="text-violet">
          {t("indianCurrency")}
          {productsCost(options)}
        </span>
      </div>
    </div>
  );
};

ProductsComponent.propTypes = {
  products: PropTypes.array,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  noAddons: PropTypes.bool,
};

export default ProductsComponent;
