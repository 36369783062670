import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { routes } from "constants";
import { useTranslation } from "react-i18next";

const PublicNavbar = () => {
  const { t } = useTranslation();

  return (
    <Navbar fixed="top" expand="lg">
      <Container className="px-3 px-sm-5 py-sm-2 fw-5">
        {/* Brand/Logo */}
        <Navbar.Brand href={routes.DEFAULT} className="text-white">
          medoc
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href={routes.ABOUT}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              <i className="bi bi-info-circle me-2"></i>
              {t("about")}
            </Nav.Link>
            <Nav.Link
              href={routes.CONTACT}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              <i className="bi bi-envelope me-1"></i>
              {t("contact")}
            </Nav.Link>
            <Nav.Link href={routes.LOGIN} className="text-white">
              <i className="bi bi-person me-1"></i>
              {t("login")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PublicNavbar;
