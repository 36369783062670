import { createSlice } from "@reduxjs/toolkit";
import { collections } from "constants";
import { get } from "firebaseConfig";

export const getCertificateTypes = () => async (dispatch) => {
  dispatch(certificateSlice.actions.getCertificateStart());

  try {
    let result = await get(collections.CERTIFICATE_TYPES);
    result = result.sort((a, b) => a.orderBy - b.orderBy);
    dispatch(certificateSlice.actions.setCertificateTypes(result));
    dispatch(certificateSlice.actions.getCertificateSuccess());
  } catch (error) {
    dispatch(certificateSlice.actions.getCertificateFailure(error));
  }
};

const certificateSlice = createSlice({
  name: "certificate",
  initialState: {
    types: [],
    loading: null,
    error: null,
  },
  reducers: {
    setCertificateTypes: (state, action) => {
      state.types = action.payload;
      state.error = null;
    },
    clearCertificateTypes: (state) => {
      state.types = [];
      state.error = null;
    },
    getCertificateStart(state) {
      state.loading = true;
      state.error = null;
    },
    getCertificateSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    getCertificateFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default certificateSlice.reducer;
