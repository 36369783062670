import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import TextExtraKeyComponent from "./textExtraKey.component";

const AddressComponent = ({ cns, options, setOptions, nonMandatory }) => {
  const { t } = useTranslation();

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5">
        {nonMandatory ? t("address") : t("addressMandatory")}
      </div>
      <div className="row">
        <TextExtraKeyComponent
          cns="col-sm-6 my-2"
          options={options}
          setOptions={setOptions}
          textKey="address"
          textExtraKey="door"
          textPlaceholder="Door no."
        />
        <TextExtraKeyComponent
          cns="col-sm-6 my-2"
          options={options}
          setOptions={setOptions}
          textKey="address"
          textExtraKey="streetName"
          textPlaceholder="Street name"
        />
        <TextExtraKeyComponent
          cns="col-sm-6 my-2"
          options={options}
          setOptions={setOptions}
          textKey="address"
          textExtraKey="city"
          textPlaceholder="City"
        />
        <TextExtraKeyComponent
          cns="col-sm-6 my-2"
          options={options}
          setOptions={setOptions}
          textKey="address"
          textExtraKey="state"
          textPlaceholder="State / Region / Province"
        />
        <TextExtraKeyComponent
          cns="col-sm-6 my-2"
          options={options}
          setOptions={setOptions}
          textKey="address"
          textExtraKey="zipcode"
          textPlaceholder="Postal/Zip Code"
        />
        <div className="col-12 col-sm-6 my-2">
          <select className="form-control font-small" aria-label="country">
            <option value="india" defaultValue>
              India
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

AddressComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  nonMandatory: PropTypes.bool,
};

export default AddressComponent;
