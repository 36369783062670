import { isMobile } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const TextAreaComponent = ({
  cns,
  options,
  setOptions,
  error,
  textareaLabel,
  textareaKey,
}) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">{textareaLabel}</div>
      <textarea
        className={`form-control fs-14 ${
          error?.key === textareaKey && "error-border"
        }`}
        rows={isMobile() ? "4" : "2"}
        placeholder={t("typeHere")}
        onChange={(e) => handleChange(textareaKey, e.target.value)}
        value={options[textareaKey]}
      />
      {error?.key === textareaKey && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

TextAreaComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  textareaLabel: PropTypes.string,
  textareaKey: PropTypes.string,
  error: PropTypes.object,
};

export default TextAreaComponent;
