import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import {
  signupFailure,
  signupStart,
  signupSuccess,
  signupUser,
} from "slices/authSlice";
import { addData } from "firebaseConfig";
import { routes } from "constants";
import { collections } from "constants";
import { handleSignupErrors } from "helpers";
import { EmailComponent, PasswordComponent, TextComponent } from "components";

const Signup = () => {
  const [options, setOptions] = useState({
    name: "",
    email: "",
    password: "",
    terms: false,
    notifications: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [error, setError] = useState();

  const handleSignup = async (e) => {
    e.preventDefault();
    const err = await handleSignupErrors(options, setError);
    if (err && err.message) {
      setError(err.message);
      return alert(err.message);
    }
    dispatch(signupStart());
    try {
      await dispatch(signupUser(options))
        .unwrap()
        .then(async (res) => {
          if (res.status === 400) {
            await dispatch(signupFailure());
            setError(res.message);
            return alert(res.message);
          }
          const userData = {
            email: options.email,
            displayName: options.name,
            permissions: {
              tnc: true,
              recordPageVisits: true,
              locationAccess: true,
              emailUpdates: true,
            },
            photoURL: res.photoURL,
            roles: ["user"],
            emailVerified: false,
            verifyEmailSentTime: new Date().getTime(),
          };

          const userRes = await addData(collections.USERS, userData);
          if (userRes.res) {
            dispatch(signupSuccess());
          }
          navigate(routes.DEFAULT);
          window.location.reload();
        });
    } catch (error) {
      dispatch(signupFailure());
    }
  };

  return (
    <div className="mt-5 card card-2">
      <h3>{t("signup")}</h3>
      <div>
        <TextComponent
          cns="mt-3"
          options={options}
          setOptions={setOptions}
          textKey="name"
          textLabel={t("name")}
          textPlaceholder={t("namePlaceholder")}
          error={error}
        />
        <EmailComponent
          cns="mt-3"
          options={options}
          setOptions={setOptions}
          textPlaceholder={t("emailPlaceholder")}
          error={error}
        />
        <PasswordComponent
          cns="mt-3"
          options={options}
          setOptions={setOptions}
          textKey="password"
          textLabel={t("password")}
          textPlaceholder={t("passwordPlaceholder")}
          error={error}
        />
        <div className="fs-12 mt-3">
          By signing up, you are agreeing to all{" "}
          <a
            href="https://medoc.life/tnc"
            target="_blank"
            rel="nooopener noreferrer"
            className="text-violet"
          >
            <u>Terms and conditions</u>
          </a>{" "}
          and{" "}
          <a
            href="https://medoc.life/privacy-policy"
            target="_blank"
            rel="nooopener noreferrer"
            className="text-violet"
          >
            <u>Privacy policy</u>
          </a>
        </div>
        {error && (
          <div className="text-danger fs-12 fw-5 mt-3">
            <i className="bi bi-exclamation-triangle-fill me-1"></i>
            {error}
          </div>
        )}
        <button
          type="submit"
          className="btn btn-violet mt-3 col-sm-3"
          disabled={loading}
          onClick={handleSignup}
        >
          {" "}
          Sign up
        </button>
      </div>
      <Link to={routes.LOGIN} className="text-violet mt-3">
        {t("redirectToLogin")}
      </Link>
    </div>
  );
};

export default Signup;
