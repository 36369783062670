import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestore } from "firebaseConfig"; // Firebase config
import {
  setUserDetails,
  setDbUserDetails,
  clearUserDetails,
} from "slices/userSlice";
import PropTypes from "prop-types";
import { collection, getDocs, query, where } from "firebase/firestore";
import { collections } from "constants";

const AuthContext = createContext();

AuthProvider.propTypes = {
  children: PropTypes.node, // Validate that 'children' is any renderable content
};

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const token = user.getIdToken();
        token.then((idToken) => {
          const userDetails = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName || "Anonymous",
            photoURL: user.photoURL || "default-avatar-url",
            token: idToken,
          };
          dispatch(setUserDetails(userDetails));
          setCurrentUser(user);
          sessionStorage.setItem("authToken", idToken); // Store token if needed
        });
      } else {
        // User is signed out
        dispatch(clearUserDetails());
        setCurrentUser(null);
        sessionStorage.removeItem("authToken"); // Clear token on sign out
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    async function getDbUser() {
      let users = await getDocs(
        query(
          collection(firestore, collections.USERS),
          where("email", "==", currentUser.email),
        ),
      );
      let userDetails;
      let userId;
      users.forEach((doc) => {
        userId = doc.id;
        userDetails = doc.data();
      });
      if (userId) {
        const u = Object.assign(userDetails, {
          id: userId,
          isDoctor: userDetails.roles.find((role) => role === "doctor"),
        });
        dispatch(setDbUserDetails(u));
        setCurrentUser(u);
      }
    }

    // apply this logic only if email exists & user id doesn't exist
    if (currentUser && !currentUser?.id && currentUser?.email) {
      getDbUser();
    }
  }, [currentUser, dispatch]);

  const value = { currentUser, isAuthenticated };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
