const handleSignupErrors = (options) => {
  let error;
  if (!options.name) {
    error = {
      message: "Name is required",
      key: "name",
    };
  } else if (!options.email) {
    error = {
      message: "Email is required",
      key: "email",
    };
  } else if (!options.password) {
    error = {
      message: "Password is required",
      key: "password",
    };
  } else if (options.password?.length < 8) {
    error = {
      message: "Password must have atleast 8 characters",
      key: "password",
    };
  }
  return error;
};

export default handleSignupErrors;
