const handleLoginErrors = (options) => {
  let error;
  if (!options.email) {
    error = {
      message: "Email is required",
      key: "email",
    };
  } else if (!options.password) {
    error = {
      message: "Password is required",
      key: "password",
    };
  }
  return error;
};

export default handleLoginErrors;
