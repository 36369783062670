import { SelectCertificateComponent } from "components";
import { routes } from "constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Dashboard() {
  const { t } = useTranslation();
  const { userDetails } = useSelector((state) => state.user);
  const [certificateTypes, setCertificateTypes] = useState(false);

  return (
    <div className="card card-2 text-center align-items-center">
      <h5 className="mb-4">{t("dashboardPageTitle")}?</h5>
      {certificateTypes ? (
        <SelectCertificateComponent />
      ) : (
        <>
          <div
            className="btn btn-lg btn-violet-outline fs-14 my-1 py-3 fw-5 col-12 col-sm-6"
            onClick={() => setCertificateTypes(true)}
          >
            <i className="bi bi-file-medical me-2"></i>
            {t("medicalCertificate")}
            <div className="fs-12 mt-2">{t("medicalCertificateCaption")}</div>
          </div>
          <Link
            to={routes.ASK_DOCTOR}
            className="btn btn-lg btn-violet-outline fs-14 my-1 py-3 fw-5 col-12 col-sm-6 d-none"
          >
            <i className="bi bi-chat-left-text me-2"></i>
            {t("doctorConsultation")}
            <div className="fs-12 mt-2">{t("doctorConsultationCaption")}</div>
          </Link>
          <Link
            to={routes.ASK_DOCTOR}
            className="btn btn-lg btn-violet-outline fs-14 my-1 py-3 fw-5 col-12 col-sm-6 d-none"
          >
            <i className="bi bi-telephone-inbound me-2"></i>
            {t("askDoctor")}
            <div className="fs-12 mt-2">{t("askDoctorCaption")}</div>
          </Link>
          {!userDetails && (
            <div className="mt-3 fs-12">
              Note: Guest users must provide all required medical documents to
              avoid delays in certificate processing.
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
