import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const CareOfComponent = ({ cns, options, setOptions, error }) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      careOf: {
        ...options.careOf,
        [key]: value,
      },
    });
  };

  return (
    <div className={`row ${cns}`}>
      <div className="col-12 fs-14 fw-5">
        <span>{t("fullNameMandatory")}</span>
        <div className="row">
          <div className="col-12 col-sm-6">
            <input
              type="text"
              className={`form-control fs-12 ${
                error?.key === "firstName" && "error-border"
              }`}
              name="firstName"
              placeholder={t("firstName")}
              value={options?.careOf?.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-6 mt-2 mt-sm-0">
            <input
              type="text"
              className={`form-control fs-12 ${
                error?.key === "lastName" && "error-border"
              }`}
              name="lastName"
              placeholder={t("lastName")}
              value={options?.careOf?.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 mt-3 fs-14 fw-5">
        <span>{t("dateOfBirthMandatory")}</span>
        <input
          type="date"
          className={`form-control fs-12 mt-1 ${
            error?.key === "dob" && "error-border"
          }`}
          name="form-question"
          placeholder="dob"
          value={options?.careOf?.dob}
          onChange={(e) => handleChange("dob", e.target.value)}
        />
      </div>
      <div className="col-12 col-sm-6 mt-3 fs-14 fw-5">
        <span>{t("ageMandatory")}</span>
        <input
          type="number"
          className={`form-control fs-12 mt-1 ${
            error?.key === "age" && "error-border"
          }`}
          name="form-question"
          placeholder={t("age")}
          value={options?.careOf?.age}
          onChange={(e) => handleChange("age", e.target.value)}
        />
      </div>
      <div className="col-12 col-sm-6 mt-3 fs-14 fw-5">
        <span>{t("genderMandatory")}</span>
        <select
          className="form-control mt-1 fs-12"
          aria-label="gender"
          value={options?.careOf?.gender}
          onChange={(e) => handleChange("gender", e.target.value)}
        >
          <option value="male">{t("male")}</option>
          <option value="female">{t("female")}</option>
          <option value="other">{t("otherGender")}</option>
        </select>
      </div>
      <div className="col-12 col-sm-6 mt-3 fs-14 fw-5">
        <span>{t("relationshipMandatory")}</span>
        <input
          type="text"
          className={`form-control fs-12 ${
            error?.key === "relationship" && "error-border"
          }`}
          name="relationship"
          placeholder={t("relationship")}
          value={options?.careOf?.relationship}
          onChange={(e) => handleChange("relationship", e.target.value)}
        />
      </div>
    </div>
  );
};

CareOfComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  error: PropTypes.object,
};

export default CareOfComponent;
