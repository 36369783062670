const handleSickLeaveErrors = (options, setError, files = []) => {
  let error;
  if (!options.firstName) {
    error = {
      message: "First name is required",
      key: "firstName",
    };
  } else if (!options.lastName) {
    error = {
      message: "Last name is required",
      key: "lastName",
    };
  } else if (!options.dob) {
    error = {
      message: "Date of birth is required",
      key: "dob",
    };
  } else if (!options.age) {
    error = {
      message: "Age is required",
      key: "age",
    };
  } else if (!options.email) {
    error = {
      message: "Email is required",
      key: "email",
    };
  } else if (!options.mobile) {
    error = {
      message: "Mobile number is required",
      key: "mobile",
    };
  } else if (
    options.mobile &&
    options.orgLocation === "india" &&
    options.mobile.length !== 12
  ) {
    error = {
      message: "Mobile number must contain 12-digits",
      key: "mobile",
    };
  } else if (!options.address.door) {
    error = {
      message: "Door no. is required",
      key: "address",
    };
  } else if (!options.address.streetName) {
    error = {
      message: "Street name is required",
      key: "address",
    };
  } else if (!options.address.city) {
    error = {
      message: "City name is required",
      key: "address",
    };
  } else if (!options.address.state) {
    error = {
      message: "State is required",
      key: "address",
    };
  } else if (!options.address.zipcode) {
    error = {
      message: "Zipcode is required",
      key: "address",
    };
  } else if (!options.leaveDateFrom) {
    error = {
      message: "Leave From date is required",
      key: "leaveDateFrom",
    };
  } else if (!options.leaveDateTo) {
    error = {
      message: "Leave To date is required",
      key: "leaveDateTo",
    };
  } else if (!options.leaveReason) {
    error = {
      message: "Leave Reason is required",
      key: "leaveReason",
    };
  } else if (!options.reasonDescription) {
    error = {
      message: "Description is required (This message prints on certificate)",
      key: "reasonDescription",
    };
  } else if (files.length < 1) {
    error = {
      message:
        "At least 1 ID document required. (Your face, name, address must be visible)",
      key: "files",
    };
  } else if (!options.orgName) {
    error = {
      message: "Organisation name is required",
      key: "orgName",
    };
  } else if (!options.orgAddress) {
    error = {
      message: "Organisation address is required",
      key: "orgAddress",
    };
  } else if (!options.product) {
    error = {
      message: "Choose a product",
      key: "product",
    };
  }
  setError(error);
  return error;
};

export default handleSickLeaveErrors;
