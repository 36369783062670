import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PaymentComponent from "./payment.component";
import { collections } from "constants";
import { routes } from "constants";

const RequestStatusComponent = ({ request }) => {
  const [requestStatus, setRequestStatus] = useState();

  useEffect(() => {
    function getRequestStatus() {
      if (request.paymentStatus === "pending") {
        setRequestStatus({
          label: "Complete payment to download certificate",
          value: "15",
          color: "danger",
          icon: "warning",
          component: (
            <PaymentComponent
              cns="float-end btn-sm"
              collectionId={request.id}
              collectionName={collections.MEDICAL_REQUESTS}
              data={request}
            />
          ),
        });
      } else if (
        request.paymentStatus === "completed" &&
        request.isEditRequired
      ) {
        setRequestStatus({
          label: "Documents Pending. Upload now!",
          value: "30",
          color: "danger",
          icon: "file-earmark-arrow-up",
        });
      } else if (request.additionalInfoRequired) {
        setRequestStatus({
          label: "Doctor asked more information",
          value: "45",
          color: "danger",
        });
      } else if (!request.doctorId) {
        setRequestStatus({
          label: "Your request is in queue (Waiting Time)",
          value: "60",
          color: "violet",
          icon: "clock",
        });
      } else if (request.doctorId && request.status === "pending") {
        setRequestStatus({
          label: "Review in progress. Your doctor will call soon.",
          value: "75",
          color: "violet",
          icon: "telephone-inbound",
        });
      } else if (request.status === "accepted") {
        setRequestStatus({
          label: "",
          value: "100",
          color: "violet",
          icon: "chat-heart-fill",
          css: "me-1",
          component: (
            <a
              href={routes.LEAVE_REVIEW}
              rel="noopener noreferrer"
              target="_blank"
              className="text-violet"
            >
              Give feedback and win 10% discount on your next certificate!
            </a>
          ),
        });
      } else if (request.status === "rejected") {
        setRequestStatus({
          label: "Your request is rejected",
          value: "100",
          color: "danger",
          icon: "file-earmark-excel",
          css: "me-1",
        });
      }
    }

    if (request?.id) getRequestStatus();
  }, [request]);

  return (
    <div className={`text-${requestStatus?.color} fs-12 fw-5`}>
      <i
        className={`bi bi-${requestStatus?.icon} me-2 fs-14 ${requestStatus?.css}`}
      ></i>
      {requestStatus?.label}
      {requestStatus?.component}
    </div>
  );
};

RequestStatusComponent.propTypes = {
  request: PropTypes.object,
};

export default RequestStatusComponent;
