import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
/** Declare imports */
import { AuthProvider } from "context/auth";
import ProtectedRoute from "./protectedRoute";
/** Declare Pages & Components */
import {
  Alerts,
  AskDoctor,
  Caretaker,
  CaretakerEdit,
  CaretakerGenerate,
  Dashboard,
  Fitness,
  FitnessEdit,
  FitnessGenerate,
  ForgotPassword,
  Form1a,
  Form1aEdit,
  Form1aGenerate,
  Login,
  PaymentSuccess,
  PrivateLayout,
  PublicLayout,
  RequestId,
  Requests,
  Settings,
  SickLeave,
  SickLeaveEdit,
  SickLeaveGenerate,
  Signup,
} from "pages";
import { routes } from "constants";
import Prescription from "pages/certificates/prescription";

function AppRouter() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Default route to /login */}
          <Route
            path={routes.DEFAULT}
            element={<Navigate to={routes.LOGIN} />}
          />

          {/* Protected routes */}
          <Route exact path={routes.DEFAULT} element={<ProtectedRoute />}>
            <Route element={<PrivateLayout />}>
              <Route exact path={routes.REQUESTS} element={<Requests />} />
              <Route exact path={routes.ALERTS} element={<Alerts />} />
              <Route exact path={routes.SETTINGS} element={<Settings />} />
              <Route
                exact
                path={`${routes.CREATE_PRESCRIPTION}/:id`}
                element={<Prescription />}
              />
              <Route
                exact
                path={`${routes.GENERATE_CT}/:id`}
                element={<CaretakerGenerate />}
              />
              <Route
                exact
                path={`${routes.GENERATE_SL}/:id`}
                element={<SickLeaveGenerate />}
              />
              <Route
                exact
                path={`${routes.GENERATE_FI}/:id`}
                element={<FitnessGenerate />}
              />
              <Route
                exact
                path={`${routes.GENERATE_FA}/:id`}
                element={<Form1aGenerate />}
              />
              <Route
                exact
                path={`${routes.EDIT_CT}/:id`}
                element={<CaretakerEdit />}
              />
              <Route
                exact
                path={`${routes.EDIT_SL}/:id`}
                element={<SickLeaveEdit />}
              />
              <Route
                exact
                path={`${routes.EDIT_FI}/:id`}
                element={<FitnessEdit />}
              />
              <Route
                exact
                path={`${routes.EDIT_FA}/:id`}
                element={<Form1aEdit />}
              />
            </Route>
          </Route>

          {/* Public routes */}
          <Route element={<PublicLayout />}>
            <Route path={routes.LOGIN} element={<Login />} />
            <Route path={routes.SIGNUP} element={<Signup />} />
            <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          </Route>

          {/* Common routes */}
          <Route
            element={
              sessionStorage.getItem("authToken") ? (
                <PrivateLayout />
              ) : (
                <PublicLayout />
              )
            }
          >
            <Route exact path={routes.DASHBOARD} element={<Dashboard />} />
            <Route exact path={routes.CREATE_CT} element={<Caretaker />} />
            <Route exact path={routes.CREATE_SL} element={<SickLeave />} />
            <Route exact path={routes.CREATE_FI} element={<Fitness />} />
            <Route exact path={routes.CREATE_FA} element={<Form1a />} />
            <Route exact path={routes.ASK_DOCTOR} element={<AskDoctor />} />
            <Route
              exact
              path={`${routes.REQUESTS}/:id`}
              element={<RequestId />}
            />
            <Route
              exact
              path={routes.PAYMENT_SUCCESS}
              element={<PaymentSuccess />}
            />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default AppRouter;
