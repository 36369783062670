const handleForm1aErrors = (options, setError, files = []) => {
  let error;
  if (!options.firstName) {
    error = {
      message: "First name is required",
      key: "firstName",
    };
  } else if (!options.lastName) {
    error = {
      message: "Last name is required",
      key: "lastName",
    };
  } else if (!options.dob) {
    error = {
      message: "Date of birth is required",
      key: "dob",
    };
  } else if (!options.age) {
    error = {
      message: "Age is required",
      key: "age",
    };
  } else if (!options.email) {
    error = {
      message: "Email is required",
      key: "email",
    };
  } else if (!options.mobile) {
    error = {
      message: "Mobile number is required",
      key: "mobile",
    };
  } else if (options.mobile && options.mobile.length !== 12) {
    error = {
      message: "Mobile number must contain 12-digits",
      key: "mobile",
    };
  } else if (!options.address.door) {
    error = {
      message: "Door no. is required",
      key: "address",
    };
  } else if (!options.address.streetName) {
    error = {
      message: "Street name is required",
      key: "address",
    };
  } else if (!options.address.city) {
    error = {
      message: "City name is required",
      key: "address",
    };
  } else if (!options.address.state) {
    error = {
      message: "State is required",
      key: "address",
    };
  } else if (!options.address.zipcode) {
    error = {
      message: "Zipcode is required",
      key: "address",
    };
  } else if (!options.birthMarks) {
    error = {
      message: "2 Birth marks are required",
      key: "birthMarks",
    };
  } else if (!options.fatherName) {
    error = {
      message: "Son/Wife/Daughter of is required",
      key: "fatherName",
    };
  } else if (files?.length < 2) {
    error = {
      message: "Address proof & Passport size photo are required",
      key: "files",
    };
  } else if (!options.product) {
    error = {
      message: "Choose a product",
      key: "product",
    };
  }
  setError(error);
  return error;
};

export default handleForm1aErrors;
