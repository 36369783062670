import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const FullNameComponent = ({
  cns,
  options,
  setOptions,
  fullNameLabel,
  nonMandatory,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    let name;
    if (key === "firstName") name = value;
    else name = name + " " + value;

    setOptions({
      ...options,
      name,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">
        {fullNameLabel
          ? fullNameLabel
          : nonMandatory
            ? t("fullName")
            : t("fullNameMandatory")}
      </div>
      <div className="row">
        <div className="col-12 col-sm-6">
          <input
            type="text"
            className={`form-control fs-14 ${
              error?.key === "firstName" && "error-border"
            }`}
            name="firstName"
            placeholder={t("firstName")}
            value={options?.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
          />
          {error?.key === "firstName" ? (
            <small className="text-danger fs-10">{error.message}</small>
          ) : (
            <small className="text-secondary fs-10 d-none">
              {t("firstName")}
            </small>
          )}
        </div>
        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
          <input
            type="text"
            className={`form-control fs-14 ${
              error?.key === "lastName" && "error-border"
            }`}
            name="lastName"
            placeholder={t("lastName")}
            value={options?.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
          />
          {error?.key === "lastName" ? (
            <small className="text-danger fs-10">{error.message}</small>
          ) : (
            <small className="text-secondary fs-10 d-none">
              {t("lastName")}
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

FullNameComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  fullNameLabel: PropTypes.string,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default FullNameComponent;
