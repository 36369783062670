import { GoBackComponent } from "components";
import Form1aComponent from "components/certificates/form1a.component";
import { routes } from "constants";
import { collections } from "constants";
import { updateData } from "firebaseConfig";
import { handleForm1aErrors } from "helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadRequestId } from "slices/getRequestSlice";

function Form1aGenerate() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { requestDetails: request } = useSelector((state) => state.request);
  const [options, setOptions] = useState(request);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const { t } = useTranslation();
  const { files } = useSelector((state) => state.document);

  useEffect(() => {
    dispatch(loadRequestId({ requestId: params?.id }));
    if (!options) {
      setOptions(request);
    }
  }, [dispatch, params, options, request]);

  const updateRequest = async () => {
    const editFiles = [...options.files, ...files];
    const err = await handleForm1aErrors(options, setError, editFiles);
    if (err && err.message) {
      return alert(err.message);
    }
    setLoading(true);
    if (window.confirm(t("confirmPaymentAlert"))) {
      const { status } = await updateData(
        collections.MEDICAL_REQUESTS,
        params.id,
        { ...options, files: editFiles },
      );
      if (status !== 200) alert("Something went wrong. Try later!");
      navigate(`${routes.REQUESTS}/${options.id}`);
    }
  };

  return (
    options?.id && (
      <>
        <GoBackComponent route={`${routes.REQUESTS}/${params.id}`} />
        <div className="card card-2">
          <h5 className="mb-4 text-center">{t("form1aCertificate")}</h5>
          <Form1aComponent
            options={options}
            setOptions={setOptions}
            error={error}
          />
          <div
            className={`btn btn-violet-rounded fs-14 fw-5 mt-4`}
            onClick={updateRequest}
            disabled={loading}
          >
            Update
          </div>
        </div>
      </>
    )
  );
}

export default Form1aGenerate;
