import { signOut } from "firebase/auth";
import { auth } from "firebaseConfig";
import { getEnv } from "helpers";

const env = getEnv();
const token = sessionStorage.getItem("authToken");

let headers;
if (token) {
  headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${token}`,
  };
} else {
  headers = {
    "content-type": "application/json",
    custom_auth_token: `wnfkJIWIAasuPUcYaVVMIZD7uxyBnozk`,
  };
}

const error = async (err) => {
  err = err.toJSON();
  if (err.message.contains("401")) {
    await signOut(auth);
    sessionStorage.clear();
  }
  return err.response;
};

export const PostAPI = async (url, data = {}) => {
  const res = await fetch(`${env.BASE_URL}/${url}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => error(err));
  return res;
};

export const GetAPI = async (url, data = {}) => {
  const res = await fetch(`${env.BASE_URL}/${url}`, {
    method: "GET",
    headers,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => error(err));
  return res;
};
