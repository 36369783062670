import { routes } from "constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { products as Products } from "constants";
import moment from "moment";
import { collections } from "constants";
import { doctors } from "constants";
import {
  GoBackComponent,
  PaymentComponent,
  ProductsComponent,
  UploadFilesComponent,
  GenderComponent,
  EmailComponent,
  MobileComponent,
  TextAreaComponent,
  FullNameComponent,
  DateComponent,
} from "components";
import { addData } from "firebaseConfig";

function AskDoctor() {
  const products = Products.filter((p) => p.category === "askDoctor").sort(
    (a, b) => a.orderBy - b.orderBy,
  );
  const { t } = useTranslation();
  const { dbUserDetails } = useSelector((state) => state.user);
  const { requestDetails } = useSelector((state) => state.createRequest);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [options, setOptions] = useState({
    status: "pending",
    name: "",
    dob: "",
    mobile: "",
    email: "",
    message: "",
    gender: "male",
    userId: dbUserDetails?.id || "guest",
    assigned: false,
    product: products[0],
    type: products[0].type,
    paymentStatus: "pending",
    files: [],
    doctorRemarks: [],
    isPrescriptionRequired: false,
    doctorId: doctors[0].id,
    promocode: "",
  });
  const { files } = useSelector((state) => state.document);

  const saveRequest = async () => {
    const err = await showErrors();
    if (err && err.message) {
      return alert(err.message);
    }
    options.name = options.firstName + " " + options.lastName;
    options.files = files;
    options.priority = options.product.priority;
    options.closeRequestTime = moment().valueOf() + 24 * 60 * 60 * 1000;
    if (!options.userId) options.userId = dbUserDetails.id;

    const { status, data } = await addData(
      collections.GENERAL_REQUESTS,
      options,
    );
    if (status !== 200) alert("Something went wrong. Try later!");

    return {
      collectionId: data.id,
      collectionName: collections.GENERAL_REQUESTS,
    };
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (options.mobile && options.mobile.length !== 12) {
      error = {
        message: "Mobile number should contain 12-digits",
        key: "mobile",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.message) {
      error = {
        message: "Message is required",
        key: "message",
      };
    } else if (!options.product) {
      error = {
        message: "Choose a product",
        key: "product",
      };
    }
    setError(error);
    return error;
  };

  return (
    <>
      <GoBackComponent route={routes.DASHBOARD} />
      <div className="card card-2 mb-5">
        <h5 className="text-center">{t("askDoctor")}</h5>
        <p className="text-center fs-12">{t("askDoctorPageDescription")}</p>
        <>
          <div className="mt-2">
            <FullNameComponent
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="row mt-2">
            <DateComponent
              cns="col-sm-6"
              dateKey="dob"
              dateLabel={t("dateOfBirthMandatory")}
              options={options}
              setOptions={setOptions}
              error={error}
            />
            <GenderComponent
              cns="col-sm-6 mt-2 mt-sm-0"
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="row mt-2">
            <EmailComponent
              cns="col-sm-6"
              options={options}
              setOptions={setOptions}
              error={error}
            />
            <MobileComponent
              cns="col-sm-6 mt-2 mt-sm-0"
              options={options}
              setOptions={setOptions}
              error={error}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponent
              options={options}
              setOptions={setOptions}
              error={error}
              textareaLabel={t("messageMandatory")}
              textareaKey="message"
            />
          </div>
          <div className="mt-2">
            <UploadFilesComponent options={options} setOptions={setOptions} />
          </div>
          <div className="mt-2">
            <ProductsComponent
              products={products}
              options={options}
              setOptions={setOptions}
            />
          </div>
          <div className="mt-2 text-center">
            <PaymentComponent
              cns="payment-float-btn"
              data={options}
              customAction={saveRequest}
              collectionName={collections.GENERAL_REQUESTS}
              collectionId={requestDetails?.id}
            />
          </div>
        </>
      </div>
    </>
  );
}

export default AskDoctor;
