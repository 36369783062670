import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { routes } from "constants";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "firebaseConfig";
import { useSelector } from "react-redux";

const PrivateNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dbUserDetails } = useSelector((state) => state.user);

  const handleLogout = async () => {
    await signOut(auth);
    sessionStorage.clear();
    navigate(routes.LOGIN);
    window.location.reload();
  };

  return (
    <Navbar fixed="top" expand="lg">
      <Container className="px-3 px-sm-5 fw-5">
        {/* Brand/Logo */}
        <Link
          to={dbUserDetails?.isDoctor ? routes.REQUESTS : routes.DASHBOARD}
          className="text-white ps-sm-5 ms-sm-5 fs-24"
        >
          medoc
        </Link>

        <Navbar.Toggle aria-controls="navbar-nav" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto text-center pe-sm-5 me-sm-3">
            <Link
              to={dbUserDetails?.isDoctor ? routes.REQUESTS : routes.DASHBOARD}
              className="text-white px-sm-3 py-2"
            >
              <i className="bi bi-house fs-20"></i>
              <div className="fs-12">{t("home")}</div>
            </Link>
            <Link to={routes.REQUESTS} className="text-white px-sm-3 py-2">
              <i className="bi bi-file-earmark-medical fs-20"></i>
              <div className="fs-12">{t("requests")}</div>
            </Link>
            <Link to={routes.ALERTS} className="text-white px-sm-3 py-2 d-none">
              <i className="bi bi-bell fs-20"></i>
              <div className="fs-12">{t("alerts")}</div>
            </Link>
            <Link
              to={routes.SETTINGS}
              className="text-white px-sm-3 py-2 d-none"
            >
              <i className="bi bi-gear fs-20"></i>
              <div className="fs-12">{t("settings")}</div>
            </Link>
            <button
              onClick={handleLogout}
              className="btn btn-violet px-sm-3 m-0"
            >
              <i className="bi bi-box-arrow-right fs-20"></i>
              <div className="fs-12 fw-5">{t("logout")}</div>
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PrivateNavbar;
