import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const AgeComponent = ({ cns, options, setOptions, nonMandatory, error }) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">
        {nonMandatory ? t("age") : t("ageMandatory")}
      </div>
      <input
        type="number"
        className={`form-control fs-14 ${
          error?.key === "age" && "error-border"
        }`}
        name="age"
        placeholder={t("age")}
        value={options.age}
        onChange={(e) => handleChange("age", e.target.value)}
      />
      {error?.key === "age" && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

AgeComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default AgeComponent;
