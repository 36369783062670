import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";

// Async action to signup the user
export const signupUser = createAsyncThunk(
  "user/signup",
  async ({ email, password }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        await sendEmailVerification(userCredential.user);
      }

      return {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || "Anonymous",
        photoURL: user.photoURL || "default-avatar-url",
      };
    } catch (error) {
      let message;
      if (error.message.includes("email-already-in-use")) {
        message = "Email already is in use";
      }
      return {
        status: 400,
        message,
      };
    }
  },
);

// Async action to forgotPassword the user
export const sendPasswordReset = (email) => async (dispatch) => {
  dispatch(authSlice.actions.resetPasswordStart());

  try {
    await sendPasswordResetEmail(auth, email);
    dispatch(
      authSlice.actions.resetPasswordSuccess("Password reset email sent."),
    );
  } catch (error) {
    dispatch(authSlice.actions.resetPasswordFailure(error.message));
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    error: null,
    loading: false,
  },
  reducers: {
    signupStart(state) {
      state.loading = true;
      state.error = null;
    },
    signupSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    signupFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetPasswordStart: (state) => {
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload; // Success message
    },
    resetPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload; // Error message
    },
  },
});

export const {
  signupStart,
  signupSuccess,
  signupFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
} = authSlice.actions;

export default authSlice.reducer;
