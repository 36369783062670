import { routes } from "constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { sendPasswordReset } from "slices/authSlice";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, error, message } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendPasswordReset(email));
  };

  return (
    <div className="mt-5 card card-2">
      <h3>{t("forgotPassword")}</h3>

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder={t("emailPlaceholder")}
          value={email}
          className={`form-control fs-14 mt-2`}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          type="submit"
          className="btn btn-violet btn-sm mt-3"
          disabled={loading}
        >
          {loading ? t("sending") : t("send")}
        </button>
      </form>
      <Link to={routes.LOGIN} className="text-violet mt-3">
        {t("redirectToLogin")}
      </Link>
      {error && <p className="text-danger">{error}</p>}
      {message && <p className="text-success">{message}</p>}
    </div>
  );
};

export default ForgotPassword;
