import { routes } from "constants";
import { capitalizeFirstLetter } from "helpers";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { assignDoctorId, loadRequestId } from "slices/getRequestSlice";
import {
  CommentsComponent,
  DoctorActionsComponent,
  DoctorIdComponent,
  Form1aActionsComponent,
  GoBackComponent,
  RequestStatusComponent,
} from "components";
import { useTranslation } from "react-i18next";
import Loading from "./loading";

const RequestId = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { dbUserDetails } = useSelector((state) => state.user);
  const { requestDetails: request, loading } = useSelector(
    (state) => state.request,
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadRequestId({ requestId: params.id }));
    window.scrollTo(0, 0);
  }, [dispatch, params]);

  useEffect(() => {
    if (
      !request?.doctorId &&
      request?.paymentStatus === "completed" &&
      dbUserDetails?.id
    ) {
      dispatch(
        assignDoctorId({ requestId: params.id, userId: dbUserDetails.id }),
      );
    }
  }, [dispatch, params, request, dbUserDetails]);

  return (
    <div>
      <GoBackComponent route={routes.REQUESTS} />
      {request && request.id && !loading ? (
        <>
          <div className="requests-wrapper">
            <div className="card card-3 col-sm-12">
              <RequestStatusComponent request={request} />
              <hr />
              {request.doctorId && (
                <div className="">
                  <DoctorIdComponent userId={request.doctorId} />
                </div>
              )}
              {!dbUserDetails?.isDoctor &&
                request.status === "pending" &&
                ((request?.category && request?.category.includes("1A")) ||
                  (request?.type && request.type.includes("1A"))) && (
                  <Form1aActionsComponent request={request} />
                )}
              <div className="row">
                {request.certLink && (
                  <div className="mt-2 col-12 col-sm-6 pl-0">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("certificateLink")}
                    </div>
                    <a
                      href={request.certLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet fw-5"
                    >
                      {t("download")}{" "}
                      <i className="fa fa-arrow-circle-o-down" />
                    </a>
                  </div>
                )}
                {request.prescriptionLink && (
                  <div className="mt-2 col-12 col-sm-6 pl-0">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("prescriptionLink")}
                    </div>
                    <a
                      href={request.prescriptionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet fw-5"
                    >
                      {t("download")}{" "}
                      <i className="fa fa-arrow-circle-o-down" />
                    </a>
                  </div>
                )}
                <div className="col-12 col-sm-6 mt-2">
                  <div className="fs-12 fw-5 text-secondary">
                    {t("requestType")}
                  </div>
                  <div>
                    {request.category ||
                      (request.type
                        ? capitalizeFirstLetter(request.type.replace(/_/g, " "))
                        : "Sick leave certificate")}
                  </div>
                </div>
                <div className="col-12 col-sm-6 mt-2">
                  <div className="fs-12 fw-5 text-secondary">
                    {t("requestNumber")}
                  </div>
                  <div
                    onClick={() => navigator.clipboard.writeText(request.id)}
                  >
                    {request.id}
                  </div>
                </div>
                <div className="mt-2 col-12 col-sm-6">
                  <div className="fs-12 fw-5 text-secondary">
                    {t("fullName")}
                  </div>
                  <div>
                    {request.firstName} {request.lastName}
                  </div>
                </div>
                <div className="mt-2 col-12 col-sm-6">
                  <div className="fs-12 fw-5 text-secondary">Last updated</div>
                  <div>
                    {moment(request.updatedAt).format("DD-MM-YYYY, HH:mm")}
                  </div>
                </div>
                {request.leaveDateFrom && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("leaveFromMandatory")}
                      </div>
                      <div>
                        {moment(request.leaveDateFrom).format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("leaveToMandatory")}
                      </div>
                      <div>
                        {moment(request.leaveDateTo).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </>
                )}
                {request.leaveReason && (
                  <div className="mt-2 col-12">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("leaveReason")}
                    </div>
                    <div>{request.leaveReason}</div>
                  </div>
                )}
                {request.reasonDescription && (
                  <div className="mt-2 col-12">
                    <div className="fs-12 fw-5 text-secondary">
                      {t("reasonDescription")}
                    </div>
                    <div>{request.reasonDescription}</div>
                  </div>
                )}
                {request.email && dbUserDetails?.id && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("emailMandatory")}
                      </div>
                      <div>{request.email}</div>
                    </div>
                  </>
                )}
                {request.mobile && dbUserDetails?.id && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("mobileMandatory")}
                      </div>
                      <div>{request.mobile}</div>
                    </div>
                  </>
                )}
                {request.dob && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("dateOfBirthMandatory")}
                      </div>
                      <div>{moment(request.dob).format("DD-MM-YYYY")}</div>
                    </div>
                  </>
                )}
                {request.gender && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("genderMandatory")}
                      </div>
                      <div>{capitalizeFirstLetter(request.gender)}</div>
                    </div>
                  </>
                )}
                {request.address && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("address")}
                      </div>
                      <div>
                        {request.address.door}, {request.address.streetName},{" "}
                        {request.address.city}, {request.address.state},{" "}
                        {request.address.zipcode}
                      </div>
                    </div>
                  </>
                )}
                {request.height && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("heightInCmMandatory")}
                      </div>
                      <div>{request.height}</div>
                    </div>
                  </>
                )}
                {request.weight && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("weightInKgMandatory")}
                      </div>
                      <div>{request.weight}</div>
                    </div>
                  </>
                )}
                {request.birthMarks && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("birthMarksMandatory")}
                      </div>
                      <div>{request.birthMarks}</div>
                    </div>
                  </>
                )}
                {request.fatherName && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("fatherNameMandatory")}
                      </div>
                      <div>{request.fatherName}</div>
                    </div>
                  </>
                )}
                {request.files && request.files.length > 0 && (
                  <div className="mt-2 col-12">
                    <div className="fs-12 fw-5 text-secondary">Documents</div>
                    {request.files.map((file, idx) => (
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-violet col-12"
                        key={idx}
                      >
                        Document {idx + 1} &nbsp;
                      </a>
                    ))}
                  </div>
                )}
                {request.careOf && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("careOf")} - {t("fullName")}
                      </div>
                      <div>
                        {request.careOf.firstName} {request.careOf.lastName}
                      </div>
                    </div>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("careOf")} - {t("dateOfBirthMandatory")}
                      </div>
                      <div>{request.careOf.dob}</div>
                    </div>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("careOf")} - {t("genderMandatory")}
                      </div>
                      <div>{request.careOf.gender}</div>
                    </div>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("careOf")} - {t("relationship")}
                      </div>
                      <div>{request.careOf.relationship}</div>
                    </div>
                  </>
                )}
                {request.orgName && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("workplaceNameMandatory")}
                      </div>
                      <div>{request.orgName}</div>
                    </div>
                  </>
                )}
                {request.orgAddress && (
                  <>
                    <div className="mt-2 col-12 col-sm-6">
                      <div className="fs-12 fw-5 text-secondary">
                        {t("workplaceAddressMandatory")}
                      </div>
                      <div>{request.orgAddress}</div>
                    </div>
                  </>
                )}
                <div className="mt-2 col-12">
                  <div className="fs-12 fw-5 text-secondary">
                    {t("product")}
                  </div>
                  <div>{request.product.name}</div>
                </div>
              </div>
              <DoctorActionsComponent request={request} />
            </div>
          </div>
          {request.paymentStatus === "completed" &&
            request.status === "pending" && (
              <CommentsComponent request={request} />
            )}
        </>
      ) : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <h5 className="pt-4 text-center">Oh no! Request not found.</h5>
          )}
        </>
      )}
    </div>
  );
};

export default RequestId;
