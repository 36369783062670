import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const GenderComponent = ({ cns, options, setOptions, nonMandatory, error }) => {
  const { t } = useTranslation();

  const handleChange = (key, value) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  return (
    <div className={`col-12 ${cns}`}>
      <div className="fs-14 fw-5 mb-1">
        {nonMandatory ? t("gender") : t("genderMandatory")}
      </div>
      <select
        className="form-control fs-14"
        aria-label="gender"
        value={options?.gender}
        onChange={(e) => handleChange("gender", e.target.value)}
      >
        <option value="male">{t("male")}</option>
        <option value="female">{t("female")}</option>
        <option value="other">{t("otherGender")}</option>
      </select>

      {error?.key === "gender" && (
        <small className="text-danger fs-10">{error.message}</small>
      )}
    </div>
  );
};

GenderComponent.propTypes = {
  cns: PropTypes.string,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  nonMandatory: PropTypes.bool,
  error: PropTypes.object,
};

export default GenderComponent;
