import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "constants";
import { handleLogout } from "helpers";

const Settings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (route) => navigate(route);

  return (
    <div className="settings-wrapper">
      <ul className="settings-list">
        <li onClick={() => handleNavigate(routes.REQUESTS)}>
          <span>{t("certificateRequests")}</span>
        </li>
        <li onClick={() => handleNavigate(routes.GENERAL_REQUESTS)}>
          <span>{t("generalRequests")}</span>
        </li>
        <li onClick={() => handleNavigate(routes.EDIT_PROFILE)}>
          <span>{t("editProfile")}</span>
        </li>
        <li onClick={() => handleNavigate(routes.GUIDELINES)}>
          <span>{t("certificateGuidelines")}</span>
        </li>
        <li>
          <span onClick={handleLogout}>{t("logout")}</span>
        </li>
      </ul>
    </div>
  );
};

export default Settings;
