import React from "react";

const Form1aActionsComponent = () => {
  return (
    <div>
      <div className="mb-3">
        <h6>Next Steps</h6>
        <div className="mt-2 fs-12">
          <i className="bi bi-check2-square me-2"></i>Step 1 - Complete your{" "}
          <a
            href="https://visionscreening.zeiss.com/en-INT"
            target="_blank"
            rel="nooopener noreferrer"
            className="text-violet fw-5"
          >
            Eye Test here
          </a>
          <br />
          <i className="bi bi-check2-square me-2"></i>Step 2 - Complete your{" "}
          <a
            href="https://hearingtest.online#theTable"
            target="_blank"
            rel="nooopener noreferrer"
            className="text-violet fw-5"
          >
            Ear Test here
          </a>
          <br />
          <i className="bi bi-check2-square me-2"></i>Step 3 - Upload results
          screenshot in comments section <br />
        </div>
      </div>
    </div>
  );
};

export default Form1aActionsComponent;
