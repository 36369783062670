import { createSlice } from "@reduxjs/toolkit";
import { addData, updateData } from "firebaseConfig";

export const createRequest = (table, data) => async (dispatch) => {
  dispatch(createRequestSlice.actions.createRequestStart());

  try {
    const result = await addData(table, data);
    dispatch(createRequestSlice.actions.setRequestDetails(result.data));
    dispatch(createRequestSlice.actions.createRequestSuccess());
  } catch (error) {
    dispatch(createRequestSlice.actions.createRequestFailure(error));
  }
};

export const updateRequest = (table, id, data) => async (dispatch) => {
  dispatch(createRequestSlice.actions.createRequestStart());

  try {
    const result = await updateData(table, id, data);
    dispatch(createRequestSlice.actions.setRequestDetails(result));
    dispatch(createRequestSlice.actions.createRequestSuccess());
  } catch (error) {
    dispatch(createRequestSlice.actions.createRequestFailure(error));
  }
};

const createRequestSlice = createSlice({
  name: "createRequest",
  initialState: {
    requestDetails: null,
    loading: null,
    error: null,
  },
  reducers: {
    setRequestDetails: (state, action) => {
      state.requestDetails = action.payload;
      state.error = null;
    },
    clearRequestDetails: (state) => {
      state.requestDetails = null;
      state.error = null;
    },
    createRequestStart(state) {
      state.loading = true;
      state.error = null;
    },
    createRequestSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    createRequestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default createRequestSlice.reducer;
