// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
  addDoc,
  query,
  getDoc,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

let firebaseConfig = {};
if (
  process.env.REACT_APP_ENV === "development" ||
  process.env.NODE_ENV === "development"
) {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_ID,
  };
}

if (process.env.REACT_APP_ENV === "production") {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
}

const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Use Firebase Emulators in development
if (
  window.location.hostname === "localhost" ||
  process.env.REACT_APP_ENV === "local"
) {
  connectAuthEmulator(auth, "http://localhost:9099"); // Emulator port for Authentication
  connectFirestoreEmulator(firestore, "localhost", 8080); // Emulator port for Firestore
  connectStorageEmulator(storage, "localhost", 9199); // Emulator port for Storage
  connectFunctionsEmulator(functions, "localhost", 5001); // Emulator port for Functions
}

export const addData = async (table, data) => {
  data.createdAt = new Date().getTime();
  data.updatedAt = new Date().getTime();

  return addDoc(collection(firestore, table), data)
    .then((res) => {
      return formatResult(200, "Successfully created", {
        id: res.id,
        path: res.path,
      });
    })
    .catch(() => formatResult(500, "Something went wrong"));
};

export const updateData = async (table, id, data) => {
  data.updatedAt = new Date().getTime();

  return updateDoc(doc(firestore, table, id), data)
    .then((res) => {
      return formatResult(200, "Successfully created", res);
    })
    .catch(() => formatResult(500, "Something went wrong"));
};

export const get = async (table) => {
  return getDocs(collection(firestore, table))
    .then((results) => {
      const object = [];
      results.forEach((doc) => {
        object.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return object.sort((a, b) => b.createdAt - a.createdAt);
    })
    .catch(() => formatResult(500, "Something went wrong"));
};

export const getId = async (table, id) => {
  return getDoc(doc(firestore, table, id))
    .then((doc) =>
      doc.exists
        ? Object.assign(doc.data(), { id })
        : formatResult(404, "No data found"),
    )
    .catch(() => formatResult(500, "Something went wrong"));
};

export const getQuery = async (table, data) => {
  const { whereQ } = data;
  const q = query(collection(firestore, table), whereQ);

  return getDocs(q)
    .then((results) => {
      const object = [];
      results.forEach((doc) => {
        object.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return object.sort((a, b) => b.createdAt - a.createdAt);
    })
    .catch(() => formatResult(500, "Something went wrong"));
};

/* Common code */
function formatResult(status, message, data = {}) {
  return { status, message, data };
}

export { auth, firestore, storage, functions };
