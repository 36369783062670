import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import userReducer from "slices/userSlice";
import authReducer from "slices/authSlice";
import requestReducer from "slices/getRequestSlice";
import createRequestReducer from "slices/createRequestSlice";
import documentReducer from "slices/documentSlice";
import certificateReducer from "slices/certificateSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    request: requestReducer,
    createRequest: createRequestReducer,
    document: documentReducer,
    certificate: certificateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: ["slices/authSlice", "slices/userSlice"],
        ignoredPaths: ["user/signup, user/login"],
      },
    }).concat(thunk),
});

export default store;
