import { PostAPI } from "api";
import { routes } from "constants";
import { updateData } from "firebaseConfig";
import { getEnv } from "helpers";
import { Loading } from "pages";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentComponent = ({
  cns,
  customAction,
  collectionName,
  collectionId: requestId,
  data,
}) => {
  const navigate = useNavigate();
  const { dbUserDetails } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (customAction) {
      const customActionResult = await customAction();

      if (customActionResult?.collectionId) {
        const id = customActionResult.collectionId || requestId;
        if (id) await saveCheckout(id);
      }
    } else {
      await saveCheckout(requestId);
    }
    setLoading(false);
  };

  const saveCheckout = async (id) => {
    setLoading(true);
    const { order } = await PostAPI(routes.CREATE_ORDER_API, {
      amount: data.product?.price,
      currency: "INR",
      requestId: id,
    });

    if (order?.id) await razorpayCheckout({ order, id });
    else alert("Something went wrong. Try later!");
  };

  const razorpayCheckout = ({ order, id }) => {
    const opts = {
      key: getEnv().RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: order.amount,
      currency: order.currency,
      name: "Medoc",
      description: data.product?.name,
      image:
        "https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg",
      order_id: order.id,
      handler: async (response) => {
        await updateData(collectionName, id, { paymentStatus: "completed" });
        navigate(routes.PAYMENT_SUCCESS, {
          state: {
            collectionId: id,
            response,
            order,
            userEmail: data.email,
          },
        });
      },
      prefill: {
        name: data.firstName,
        email: data.email,
        contact: data.mobile,
      },
      notes: {
        displayName: data.firstName,
        userId: dbUserDetails?.id || "guest",
        doctorId: data.doctorId,
        requestId: id,
        type: data.type,
      },
      theme: {
        color: "#684490",
      },
    };
    const rzPopup = new window.Razorpay(opts);
    rzPopup.on("payment.failed", function (response) {
      setLoading(false);
      console.error("resp", response);
    });
    rzPopup.open();
  };

  return loading ? (
    <>
      <div className="fw-5">
        Payment is in progress. Please donot refresh your screen...
      </div>
      <Loading />
    </>
  ) : (
    <div
      className={`btn btn-violet-rounded fs-14 fw-5 ${cns}`}
      onClick={handleSubmit}
      disabled={loading}
    >
      Complete Payment
    </div>
  );
};

PaymentComponent.propTypes = {
  cns: PropTypes.string,
  customAction: PropTypes.func,
  collectionName: PropTypes.string,
  collectionId: PropTypes.string,
  data: PropTypes.object,
};

export default PaymentComponent;
